import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import dotenv from 'dotenv'
import router from './router'
import store from './store'
import { ability } from '@/store'
import { abilitiesPlugin } from '@casl/vue'
import VueSocialSharing from 'vue-social-sharing'
import axios from "axios";

Vue.use(VueSocialSharing);

Vue.config.productionTip = false
Vue.use(abilitiesPlugin, ability)

dotenv.config()

axios.defaults.baseURL = process.env.VUE_APP_API_URL || '';

new Vue({
	vuetify,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
