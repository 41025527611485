export const Str = {
	headline: (str) => {
		if (!str) return '';

		str = str.replaceAll('_', ' ').replaceAll('-', ' ');

		return str.replaceAll(/\w\S*/g, (t) => t.charAt(0).toUpperCase() + t.substring(1).toLowerCase());
	},
	ucFirst: (str) => {
		str = str.toLowerCase();

		return str.charAt(0).toUpperCase() + str.slice(1);
	},
	kebab: (str) => {
		return str
			.replace(/([a-z])([A-Z])/g, '$1-$2') // Insert a dash between lower case and upper case characters
			.replace(/\s+/g, '-') // Replace spaces with -
			.toLowerCase(); // Convert the whole string to lower case
	},
};