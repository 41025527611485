import apiCall from '@/utils/api'
import axios from "axios";

const state = {
    openSales: [],
    searchSales: [],
    closedSales: [],
    publicSales: [],
    openSalePagination: {
        search: ' ',
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10
    },
    searchSalePagination: {
        search: ' ',
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10
    },
    closedSalePagination: {
        search: ' ',
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10
    },
    publicSalePagination: {
        search: ' ',
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10
    },

    openSaleLoader: false,
    closedSaleLoader: false,
    publicSaleLoader: false,
    searchSaleLoader: false,
};

const getters = {
    openSales: (state) => state.openSales,
    closedSales: (state) => state.closedSales,
    publicSales: (state) => state.publicSales,

    searchSales: (state) => state.searchSales,
    searchSaleLoader: (state) => state.searchSaleLoader,
    searchSalePagination: (state) => state.searchSalePagination,

    openSaleLoader: (state) => state.openSaleLoader,
    closedSaleLoader: (state) => state.closedSaleLoader,
    publicSaleLoader: (state) => state.publicSaleLoader,


    openSalePagination: (state) => state.openSalePagination,
    closedSalePagination: (state) => state.closedSalePagination,
    publicSalePagination: (state) => state.publicSalePagination,
}

const actions = {
    async fetchOpenSales({commit}, page) {
        const response = await apiCall({url: `/api/sales?type=open&page=${page}`, method: 'GET'});
        commit('setOpenSales', response)
        commit('stopOpenSaleLoader', response)
    },
    async fetchClosedSales({commit}, params) {
        const response = await axios.get(`/api/sales`, {
            params: {
                type:'closed',
                ...params
            }
        });
        commit('setClosedSales', response.data)
        commit('stopClosedSaleLoader', response.data)
    },
    async fetchSearchSales({commit}, page) {
        const response = await apiCall({url: `/api/sales?page=${page}`, method: 'GET'});
        commit('setSearchSales', response)
        commit('stopSearchSaleLoader', response)
    },
    async fetchPublicSales({commit}, page) {
        const response = await apiCall({url: `/api/sales?type=public&page=${page}`, method: 'GET'});
        commit('setPublicSales', response)
        commit('stopPublicSaleLoader', response)
    },
    async filterOpenSales({commit, state}, resp) {
        commit('setOpenSales', resp)
    },
    async filterClosedSales({commit, state}, resp) {
        commit('setClosedSales', resp)
    },
    async filterPublicSales({commit, state}, resp) {
        commit('setPublicSales', resp)
    },
    async filterSearchSales({commit, state}, resp) {
        commit('setSearchSales', resp)
    },
    async startOpenSaleLoader({commit, state}, resp) {
        commit('startOpenSaleLoader', resp)
    },
    async startClosedSaleLoader({commit, state}, resp) {
        commit('startClosedSaleLoader', resp)
    },
    async startPublicSaleLoader({commit, state}, resp) {
        commit('startPublicSaleLoader', resp)
    },
    async stopOpenSaleLoader({commit, state}, resp) {
        commit('stopOpenSaleLoader', resp)
    },
    async stopClosedSaleLoader({commit, state}, resp) {
        commit('stopClosedSaleLoader', resp)
    },
    async stopPublicSaleLoader({commit, state}, resp) {
        commit('stopPublicSaleLoader', resp)
    },
    async startSearchSaleLoader({commit, state}, resp) {
        commit('startSearchSaleLoader', resp)
    },
    async stopSearchSaleLoader({commit, state}, resp) {
        commit('stopSearchSaleLoader', resp)
    },
};

const mutations = {
    setOpenSales: (state, openSales) => {
        state.openSales = openSales.data
        state.openSalePagination.current_page = openSales.current_page
        state.openSalePagination.total = openSales.total
        state.openSalePagination.per_page = openSales.per_page
    },
    setClosedSales: (state, closedSales) => {
        state.closedSales = closedSales.data
        state.closedSalePagination.current_page = closedSales.current_page
        state.closedSalePagination.total = closedSales.total
        state.closedSalePagination.sales_sum = closedSales.sales_sum
        state.closedSalePagination.per_page = closedSales.per_page
    },
    setPublicSales: (state, publicSales) => {
        state.publicSales = publicSales.data
        state.publicSalePagination.current_page = publicSales.current_page
        state.publicSalePagination.total = publicSales.total
        state.publicSalePagination.per_page = publicSales.per_page
    },
    setSearchSales: (state, sales) => {
        state.searchSales = sales.data
        state.searchSalePagination.current_page = sales.current_page
        state.searchSalePagination.total = sales.total
        state.searchSalePagination.per_page = sales.per_page
    },
    startSearchSaleLoader: (state) => state.searchSaleLoader = true,
    stopSearchSaleLoader: (state) => state.searchSaleLoader = false,

    startOpenSaleLoader: (state) => state.openSaleLoader = true,
    stopOpenSaleLoader: (state) => state.openSaleLoader = false,
    startClosedSaleLoader: (state) => state.closedSaleLoader = true,
    stopClosedSaleLoader: (state) => state.closedSaleLoader = false,
    startPublicSaleLoader: (state) => state.publicSaleLoader = true,
    stopPublicSaleLoader: (state) => state.publicSaleLoader = false,
};

export default {
    state,
    getters,
    actions,
    mutations
}
