import axios from 'axios'

const apiCall = ({ url, data, method }) => new Promise((resolve, reject) => {
	console.log(url)

	try {
		axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
		if (method == 'GET') {
			axios.get(url, { crossdomain: true })
				.then((response) => resolve(response.data))
				.catch((error) => reject(new Error(error)))
		} else if (method == 'POST') {
			axios.post(url, data)
				.then((response) => resolve(response.data))
				.catch((error) => reject(new Error(error)));
		} else if (method == 'PUT') {
			axios.put(url, data)
				.then((response) => resolve(response.data))
				.catch((error) => reject(new Error(error)));
		} else if (method == 'DELETE') {
			axios.delete(url, data)
				.then((response) => resolve(response.data))
				.catch((error) => reject(new Error(error)));
		}
	} catch (err) {
		reject(new Error(err))
	}
})

export default apiCall