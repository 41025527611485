<template>
	<div class="transactions" v-if="$can('view-transactions')">
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<div class="pa-5">
			<v-layout column>
				<v-flex xs12>
					<div class="title">Mpesa Transactions</div>
				</v-flex>
				<div v-if="view === 'default'">
					<v-flex xs12 class="mt-5 mb-2">
						<v-card elevation="0" class="header">
							<v-layout column class="pa-2">
								<v-flex xs12 class="mt-1 align-self-end">
									<v-layout row wrap class="mt-2">
										<v-flex xs12 md4>
											<div class="pa-1">
												<div>Parent/Guardian</div>
												<v-autocomplete v-model="filterData.user_id"
												                :loading="parentLoading" :items="parentItems"
												                :search-input.sync="searchUsers" item-value="id"
												                item-text="full_name"
												                class="text_field background my-2" flat hide-no-data
												                hide-details outlined dense
												                @change="fetchTransactions()"
												/>
											</div>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-1">
												<div>Start</div>
												<v-menu :close-on-content-click="false" max-width="290"
												        transition="scale-transition"
												        v-model="fromDatePicker" offset-y>
													<template v-slot:activator="{ on, attrs }">
														<v-text-field outlined dense :value="formatFromDate"
														              slot="activator" readonly
														              v-bind="attrs"
														              v-on="on"
														              class="text_field background my-2"></v-text-field>
													</template>
													<v-date-picker v-model="filterData.start_date"
													               :max="filterData.end_date"
													               @change="closeFromDate"></v-date-picker>
												</v-menu>
											</div>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-1">
												<div>End</div>
												<v-menu :close-on-content-click="false" max-width="290"
												        transition="scale-transition"
												        v-model="toDatePicker" offset-y>
													<template v-slot:activator="{ on, attrs }">
														<v-text-field outlined dense :value="formatToDate"
														              slot="activator" readonly
														              v-bind="attrs"
														              v-on="on"
														              class="text_field background my-2"></v-text-field>
													</template>
													<v-date-picker v-model="filterData.end_date"
													               :min="filterData.start_date"
													               :max="dateToday"
													               @change="closeToDate"></v-date-picker>
												</v-menu>
											</div>
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>
						</v-card>
					</v-flex>
					<v-flex>
						<v-layout row wrap class="justify-end px-2">
							<v-flex xs12 md3>
								<div class="pa-1 text-end">
									<v-btn depressed class="white--text green text-none my-2"
									       @click="resetData()">
										Reset
										<v-icon right>mdi-undo</v-icon>
									</v-btn>
								</div>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 class="mt-5">
						<div v-if="transactions.length === 0">
							<v-card elevation="0">
								<v-layout row wrap>
									<v-flex xs12 md1>
										<v-container fill-height fluid>
											<v-row align="center" justify="center">
												<v-col class="text-center">
													<v-icon large class="primary--text">
														mdi-alert-circle-outline
													</v-icon>
												</v-col>
											</v-row>
										</v-container>
									</v-flex>
									<v-flex xs12 md11>
										<v-container fill-height fluid>
											<v-row align="center" justify="center">
												<v-col class="text-center">
													No Mpesa Transaction Found
												</v-col>
											</v-row>
										</v-container>
									</v-flex>
								</v-layout>
							</v-card>
						</div>
						<div v-else>
							<div class="hidden-sm-and-down mt-3">
								<v-progress-linear v-if="loading" height="1" indeterminate color="primary">
								</v-progress-linear>
								<v-simple-table>
									<template v-slot:default>
										<thead class="header">
										<tr class="accent">
											<th class="text-left text--text">Sale #</th>
											<th class="text-left text--text">Parent/<small>Student</small></th>
											<th class="text-left text--text">Transaction Code</th>
											<th class="text-left text--text">Description</th>
											<th class="text-right text--text">Total</th>
											<th class="text-left text--text">Created</th>
										</tr>
										</thead>
										<tbody>
										<tr v-for="item in transactions" :key="item.id">
											<td>{{ item.sale.sale_no }}</td>
											<td>
												<div>{{ item.sale.user?.full_name }}</div>
												<small>
													<span v-if="item.sale.sale_student!=null">
														{{ item.sale.sale_student.student?.full_name }}
													</span>
												</small>
											</td>
											<td>{{ item.sale.transaction_code }}</td>
											<td>{{ item.ResponseDescription }}</td>
											<td align="right">
												{{ item.sale.total | formatNumber }}
											</td>
											<td>
												<div>{{ item.created_at | moment("DD/MM/YYYY") }}</div>
												<small>{{ item.created_at | moment("hh:mm A") }}</small>
											</td>
										</tr>
										</tbody>
									</template>
								</v-simple-table>
							</div>
						</div>
					</v-flex>
					<v-flex xs12 class="mt-10">
						<v-layout row wrap>
							<v-flex xs12>
								<div align="left">
									<v-pagination v-if="length !== 0 && length !== 1" :length="length"
									              total-visible="10"
									              v-model="transactions.current_page" @input="changePage()" circle>
									</v-pagination>
								</div>
							</v-flex>
						</v-layout>
					</v-flex>
				</div>
			</v-layout>
		</div>
	</div>
</template>
<script>
import Vue from 'vue'
import axios from "axios"
import apiCall from "@/utils/api";
import { mapActions, mapGetters } from "vuex";

let numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

export default {
	components: {},
	data() {
		return {
			view: "default",
			loading: false,
			parentLoading: false,
			valid: true,
			message: "",
			color: "",
			delete: false,
			confirm: false,
			snackbar: false,

			searchUsers: null,
			parentItems: [],

			fromDatePicker: false,
			toDatePicker: false,

			transactions: [],
			filterData: {
				user_id: null,
				start_date: null,
				end_date: null
			},
			monthNames: [
				{ name: "January" },
				{ name: "February" },
				{ name: "March" },
				{ name: "April" },
				{ name: "May" },
				{ name: "June" },
				{ name: "July" },
				{ name: "August" },
				{ name: "September" },
				{ name: "October" },
				{ name: "November" },
				{ name: "December" },
			],
			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],
		};
	},
	watch: {
		searchUsers(val) {
			val && val !== this.filterData.user_id && this.findUser(val);
		},
		searchParts(val) {
			val && val !== this.filterData.part_id && this.findPart(val);
		},
		toDatePicker(val) {
			val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
		}
	},
	created() {
		if (this.$can("view-transactions")) {
			this.fetchTransactions();
		}
	},
	methods: {
		...mapActions([
			''
		]),
		changePage() {
			this.loading = true;
			this.filterData.page = this.transactions.current_page
			this.fetchTransactions()
		},
		closeFromDate() {
			this.fromDatePicker = false;
			this.fetchTransactions()
		},
		closeToDate() {
			this.toDatePicker = false;
			this.fetchTransactions()
		},
		fetchTransactions() {
			this.loading = true;
			axios.get("/api/mpesa", { params: this.filterData })
				.then(({ data: resp }) => {
					this.transactions = resp.data
					this.transactions.current_page = resp.current_page
					this.transactions.total = resp.total
					this.transactions.per_page = resp.per_page

					this.loading = false;
				})
				.catch((error) => {
					console.log(error.response);
					this.loading = false;
					this.message = "An Error Occurred";
					this.color = "error";
					this.snackbar = true;
				});
		},
		findUser(v) {
			this.parentLoading = true;

			apiCall({
				url: `/api/users?search=${v}`,
				method: "GET",
			})
				.then((resp) => {
					this.parentItems = resp.data;
					this.parentLoading = false;
				})
				.catch(() => {
					this.parentLoading = false;
				});

		},
		resetData() {
			this.filterData = {
				user_id: null,
				start_date: null,
				end_date: null
			}

			this.fetchTransactions()
		},

	},
	computed: {
		...mapGetters([]),
		length: function () {
			return Math.ceil(this.transactions.total / this.transactions.per_page);
		},
		currentYear() {
			let today = new Date();

			return today.getFullYear();
		},
		dateToday() {
			let today = new Date();

			return today.getFullYear() +
				"-" +
				this.months[today.getMonth()] +
				"-" +
				("0" + today.getDate()).slice(-2);
		},
		formatFromDate() {
			if (this.filterData.start_date != null) {
				const d = new Date(this.filterData.start_date);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		formatToDate() {
			if (this.filterData.end_date != null) {
				const d = new Date(this.filterData.end_date);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		formatYear() {
			if (this.newResource.year != null) {
				const d = new Date(this.newResource.year);
				const year = d.getFullYear();

				this.newResource.year = year

				return year;
			}
		},
	},
};
</script>
