<template>
	<div class="parts" v-if="$can('part_view')">
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<v-dialog transition="dialog-bottom-transition" max-width="600" v-model="imageDialog">
			<v-card max-width="600">
				<v-toolbar flat>
					Upload Image
					<v-spacer></v-spacer>
					<v-btn icon @click="imageDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>

				<v-form ref="imageForm" v-model="imageValid" lazy-validation>
					<v-layout column>
						<v-flex xs12>
							<v-file-input class="mx-2 mt-3" :loading="imageLoading" :rules="fileRules" filled dense
							              show-size chips accept="image/*" v-model="imageFile"
							              label="Select Image"></v-file-input>
						</v-flex>
						<v-flex xs12>
							<div class="mx-3 mb-5">
								<v-btn depressed block class="primary text-none" @click="uploadImage"
								       :loading="imageLoading" :disabled="!imageValid">
									Submit
									<v-icon right dark>mdi-upload</v-icon>
								</v-btn>
							</div>
						</v-flex>
					</v-layout>
				</v-form>
			</v-card>
		</v-dialog>
		<div class="pa-5">
			<v-layout column>
				<div v-if="view == 'default'">
					<v-card elevation="0" class="mt-5">

						<v-flex xs12 class="mt-10 mb-2">
							<div class="mx-5">
								<v-layout row wrap>
									<v-flex xs12 md6>
										<div v-if="length != 0" align="left" class="mt-1">
											<b class="display-1 primary--text">Total: </b>
											{{ partPagination.total |formatNumber }}
										</div>
									</v-flex>
									<v-flex xs12 md4>
										<div class="pa-1">
											<v-text-field class="text_field background" outlined dense rounded
											              label="Search by name or serial no." append-icon="mdi-undo-variant"
											              @click:append="resetSearch()"
											              v-on:keyup.enter="search" v-model="searchTerm"
											              @input="enableSearch()"></v-text-field>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1" align="right">
											<v-btn class="button mx-1" icon @click="search" :loading="searchLoader"
											       :disabled="searchButtonDisabled">
												<v-icon class="secondary--text">mdi-magnify</v-icon>
											</v-btn>
											<v-btn icon class="button mx-1" @click="changeView('create')"
											       v-if="$can('part_create')">

												<v-icon class="primary--text">mdi-plus-circle-outline</v-icon>
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</v-flex>
						<v-divider class="mt-9 mx-5"></v-divider>
						<v-progress-linear v-if="partLoader" height="1" indeterminate color="primary">
						</v-progress-linear>
						<v-flex xs12 class="mt-5">
							<div v-if="parts.length == 0">
								<v-card elevation="0">
									<v-layout row wrap>
										<v-flex xs12 md1>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														<v-icon large class="primary--text">
															mdi-alert-circle-outline
														</v-icon>
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
										<v-flex xs12 md11>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														No Part Found
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
									</v-layout>
								</v-card>
							</div>
							<div v-else>
								<div class="hidden-sm-and-down">
									<v-card elevation="0" class="pa-2">
										<v-simple-table>
											<template v-slot:default>
												<thead class="header">
												<tr class="accent">
													<th class="text-left text--text">Logo</th>
													<th class="text-left text--text">Name</th>
													<th class="text-left text--text">Serial No</th>
													<th class="text-left text--text">Status</th>
													<th class="text-left text--text">Date Created</th>
													<th class="text-right text--text">Actions</th>
												</tr>
												</thead>
												<tbody>
												<tr v-for="item in parts" :key="item.id">
													<td>
														<div class="my-2">
															<v-avatar size="48" v-if="item.image==null">
																<v-img
																	max-height="150"

																	src="img/book-silhouette-vector-14.png"
																>
																</v-img>
															</v-avatar>
															<v-avatar size="48" v-else>
																<v-img
																	max-height="150"
																	:src="path+'/storage/part_pictures/'+item.image"
																>
																</v-img>
															</v-avatar>
														</div>
													</td>
													<td>{{ item.name }}</td>
													<td>{{ item.serial_no }}</td>
													<td>
														<div v-if="item.status == 1">Active</div>
														<div v-else>Inactive</div>
													</td>
													<td>
														{{
															item.created_at | moment("DD/MM/YYYY - hh:mm a")
														}}
													</td>
													<td>
														<div align="right">
															<v-btn icon v-if="$can('part_view')" @click="showPart(item)"
															       class="button mr-1 universal--text">
																<v-icon small> mdi-eye</v-icon>
															</v-btn>
															<v-btn v-if="$can('part_edit')" icon
															       class="button mr-1 success--text"
															       @click="editPart(item)">
																<v-icon small> mdi-pencil</v-icon>
															</v-btn>
															<v-btn v-if="$can('part_archive')" icon
															       class="button mr-1 error--text"
															       @click="deletePart(item)" :loading="loading && partIndex == item.id
                                                                        ">
																<v-icon small> mdi-delete</v-icon>
															</v-btn>
															<v-tooltip top v-if="$can('part_activate') &&
                                                                    item.status == 0
                                                                    ">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 red--text"
																	       @click="activatePart(item)" :loading="activateLoading &&
                                                                                partIndex == item.id
                                                                                " v-bind="attrs" v-on="on">
																		<v-icon small>
																			mdi-toggle-switch-off-outline
																		</v-icon>
																	</v-btn>
																</template>
																<span>Activate Part</span>
															</v-tooltip>
															<v-tooltip top v-if="$can('part_deactivate') &&
                                                                    item.status == 1
                                                                    ">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 green--text"
																	       @click="deactivatePart(item)" :loading="deactivateLoading &&
                                                                                partIndex == item.id
                                                                                " v-bind="attrs" v-on="on">
																		<v-icon small> mdi-toggle-switch</v-icon>
																	</v-btn>
																</template>
																<span>Deactivate Part</span>
															</v-tooltip>
															<v-tooltip top v-if="$can('log_view')">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 purple--text"
																	       @click="viewLog(item)" v-bind="attrs"
																	       v-on="on">
																		<v-icon small> mdi-script-text</v-icon>
																	</v-btn>
																</template>
																<span>Log</span>
															</v-tooltip>
														</div>
													</td>
												</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>
								</div>
								<div class="hidden-md-and-up">
									<div>
										<v-layout column>
											<template v-for="(part, index) in parts">
												<div :key="index">
													<v-flex xs12 class="mb-2">
														<v-card elevation="0" style="border-radius: 25px;" outlined>
															<div class="pa-5">
																<v-layout column>
																	<v-flex xs12>
																		<v-layout row wrap>
																			<v-flex xs2>
																				<div align="center">
																					<v-avatar size="48"
																					          v-if="part.image==null">
																						<v-img
																							max-height="150"

																							src="img/book-silhouette-vector-14.png"
																						>
																						</v-img>
																					</v-avatar>
																					<v-avatar size="48" v-else>
																						<v-img
																							max-height="150"
																							:src="path+'/storage/part_pictures/'+part.image"
																						>
																						</v-img>
																					</v-avatar>
																				</div>
																			</v-flex>
																			<v-flex xs9>
																				<div class="title primary--text ml-3">
																					<b>{{ part.name }}</b>
																				</div>
																			</v-flex>
																			<v-flex xs1>
																				<v-btn
																					depressed
																					class="red--text"
																					:loading="
                                                                            loading && partIndex == part.id
                                                                        "
																					icon
																					@click="deletePart(part)"
																					v-if="$can('part_archive')"
																				>
																					<v-icon> mdi-delete</v-icon>
																				</v-btn>
																			</v-flex>
																		</v-layout>

																	</v-flex>
																	<v-divider class="mt-7 mb-3"></v-divider>


																	<v-flex xs12 class="mt-1">
																		<v-layout column>

																			<v-flex xs12 class="mt-5">
																				<v-layout row wrap>
																					<v-flex xs4>
																						<b>Status</b>
																					</v-flex>
																					<v-flex xs8>
																						<div v-if="part.status == 1">
																							Active
																						</div>
																						<div v-else>Inactive</div>
																					</v-flex>
																				</v-layout>
																			</v-flex>
																			<v-flex xs12 class="mt-5">
																				<v-layout row wrap>
																					<v-flex xs4>
																						<b>Date Created</b>
																					</v-flex>
																					<v-flex xs8>
																						{{
																							part.created_at
																								| moment("DD/MM/YYYY - hh:mm a")
																						}}
																					</v-flex>
																				</v-layout>
																			</v-flex>
																			<v-flex xs12 class="mt-5">
																				<v-layout row wrap>
																					<v-flex xs12
																					        v-if="$can('part_view')">
																						<div>
																							<v-btn depressed rounded
																							       class="primary text-none white--text mt-3"
																							       block @click="
                                                                                                    showPart(part)
                                                                                                    ">View
																								<v-icon right> mdi-eye
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<v-flex xs12
																					        v-if="$can('part_edit')">
																						<div>
																							<v-btn depressed rounded
																							       class="blue text-none white--text mt-1"
																							       block @click="
                                                                                                    editPart(part)
                                                                                                    ">Edit
																								<v-icon right>
																									mdi-border-color
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<v-flex xs12 v-if="$can('part_activate') &&
        part.status == 0">
																						<div>
																							<v-btn depressed rounded
																							       class="green text-none white--text mt-1"
																							       block :loading="activateLoading &&
                                                                                                    partIndex == part.id
                                                                                                    " @click="
        activatePart(part)
        ">Activate Part
																								<v-icon right>
																									mdi-toggle-switch-off-outline
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<v-flex xs12 v-if="$can('part_deactivate') &&
        part.status == 1">
																						<div>
																							<v-btn depressed rounded
																							       class="red text-none white--text mt-1"
																							       block :loading="deactivateLoading &&
                                                                                                    partIndex == part.id
                                                                                                    " @click="
        deactivatePart(part)
        ">Deactivate Part
																								<v-icon right>
																									mdi-toggle-switch
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<v-flex xs12
																					        v-if="$can('log_view')">
																						<div class="mt-1">
																							<v-btn
																								block depressed
																								rounded
																								class="purple text-none white--text"
																								@click="viewLog(part)"
																								v-bind="attrs"
																								v-on="on">
																								Log
																								<v-icon small>
																									mdi-script-text
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																				</v-layout>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</v-layout>
															</div>
														</v-card>
													</v-flex>
												</div>
											</template>
										</v-layout>
									</div>
								</div>
							</div>
						</v-flex>
					</v-card>
					<v-layout column class="mt-5">
						<v-flex xs12>
							<div align="left">
								<v-pagination v-if="length != 0" :length="length" total-visible="10"
								              v-model="partPagination.current_page" @input="changePage()" circle>
								</v-pagination>
							</div>
						</v-flex>

					</v-layout>
				</div>
				<div v-if="view == 'create'">
					<v-flex class="mt-5">
						<v-card outlined>
							<v-card-title class="accent text--text">
								<v-img v-if="newPart.id != null" src="img/badge.png" max-width="40"></v-img>
								<v-spacer></v-spacer>
								<div v-if="newPart.id == null">Add Part</div>
								<div v-else>Edit Part</div>
								<v-spacer></v-spacer>
								<v-btn icon @click="changeView('default')">
									<v-icon class="text--text"> mdi-close</v-icon>
								</v-btn>
							</v-card-title>
							<v-card-text>
								<v-form v-model="valid" ref="form" lazy-validation>
									<v-layout row wrap class="mt-5">
										<v-flex xs12 md4>
											<div class="pa-3">
												<div>Name<span class="error--text">*</span></div>
												<v-text-field class="text_field background my-2" outlined dense
												              v-model="newPart.name" :rules="inputRules"></v-text-field>
											</div>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-3">
												<div>Brief<span class="error--text">*</span></div>
												<v-text-field class="text_field background my-2" outlined dense
												              v-model="newPart.brief"
												              :rules="inputRules"></v-text-field>
											</div>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-3">
												<div>Serial No<span class="error--text">*</span></div>
												<v-text-field class="text_field background my-2" outlined dense
												              v-model="newPart.serial_no"
												              :rules="inputRules"></v-text-field>
											</div>
										</v-flex>
										<v-flex xs12>
											<div class="pa-3">
												<div>Description<span class="error--text">*</span></div>
												<div class="black--text">
													<ckeditor :editor="editor" v-model="newPart.description"
													          :config="editorConfig" :rules="inputRules"></ckeditor>
												</div>
											</div>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-3 mx-2">
												<v-switch
													v-model="purchaseRestrictions"
													inset
													label="Apply Max Purchase Restriction"
												></v-switch>
											</div>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-3" v-if="purchaseRestrictions==true">
												<v-text-field number class="text_field background my-2" outlined dense
												              v-model="newPart.max_purchase"
												              :rules="inputRules"></v-text-field>
											</div>
										</v-flex>


									</v-layout>
								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn depressed class="primary text-none mt-3" @click="save" :disabled="!valid"
								       :loading="loading">
									Save
									<v-icon right> mdi-content-save</v-icon>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-flex>
				</div>
				<div v-if="view == 'show'">
					<v-card outlined class="mt-5">
						<v-progress-linear v-if="stockLoader" height="1" indeterminate color="primary">
						</v-progress-linear>
						<v-card-title class="accent text--text">
							<v-spacer></v-spacer>
							<div>View Part</div>
							<v-spacer></v-spacer>
							<v-btn icon @click="changeView('default')">
								<v-icon> mdi-close</v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text>
							<div class="pa-1">
								<v-tabs show-arrows v-model="partTab">
									<v-tabs-slider color="primary"></v-tabs-slider>

									<v-tab @click="activeTab = 'details'">
										<div class="text-none primary--text">Details</div>
									</v-tab>
									<v-tab @click="activeTab = 'stocks'" v-if="$can('stock_view')">
										<div class="text-none primary--text">
											Stock ({{
												stockPagination.total
											}})
										</div>
									</v-tab>
									<v-tab @click="activeTab = 'categories'" v-if="$can('category_view')">
										<div class="text-none primary--text">
											Categories ({{
												categoryPagination.total
											}})
										</div>
									</v-tab>
									<v-tab @click="activeTab = 'levels'" v-if="$can('level_view')">
										<div class="text-none primary--text">
											Levels ({{
												levelPagination.total
											}})
										</div>
									</v-tab>
									<v-tab @click="activeTab = 'learningAreas'" v-if="$can('learning_area_view')">
										<div class="text-none primary--text">
											Learning Areas ({{
												learningAreaPagination.total
											}})
										</div>
									</v-tab>
								</v-tabs>
								<div v-if="activeTab == 'details'">
									<v-card-text>
										<div class="pa-3 mt-3">
											<v-layout column>
												<v-flex xs12>
													<v-layout row wrap>
														<v-flex xs12 md2>
															<div class="mt-1">
																<v-img v-if="assignedPart.image == null"
																       src="img/logo.gif">
																</v-img>
																<v-img v-else
																       :src="path + '/storage/part_pictures/' + assignedPart.image">
																</v-img>

															</div>
															<div align="center" class="mt-5">
																<v-btn class="primary text-none" depressed
																       @click="imageDialog = true"
																       v-if="$can('part_image_upload')">
																	Upload Image
																	<v-icon right>mdi-file-upload</v-icon>
																</v-btn>
															</div>
														</v-flex>
														<v-flex xs12 md10>
															<div class="mx-8">
																<v-layout column>
																	<v-flex xs12>
																		<div class="mx-3">
																			<v-layout row wrap>
																				<v-flex xs12 md6>
																					<v-layout column>
																						<v-flex xs12 class="mt-6">
																							<v-layout row wrap>
																								<v-flex xs12 md4>
																									<b>Part Name: </b>
																								</v-flex>
																								<v-flex xs12 md8>
																									<div>
																										{{
																											assignedPart.name
																										}}
																									</div>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-flex xs12 class="mt-6">
																							<v-layout row wrap>
																								<v-flex xs12 md4>
																									<b>Serial No: </b>
																								</v-flex>
																								<v-flex xs12 md8>
																									<div>
																										{{
																											assignedPart.serial_no
																										}}
																									</div>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 md6>
																					<v-layout column>

																						<v-flex xs12 class="mt-6">
																							<v-layout row wrap>
																								<v-flex xs12 md4>
																									<b>Status: </b>
																								</v-flex>
																								<v-flex xs12 md8>
																									<div v-if="assignedPart.status == 1
                                                                                                            ">
																										Active
																									</div>
																									<div v-else>Inactive
																									</div>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-flex xs12 class="mt-6">
																							<v-layout row wrap>
																								<v-flex xs12 md4>
																									<b>Max Purchase
																										Restriction: </b>
																								</v-flex>
																								<v-flex xs12 md8>
																									<div v-if="assignedPart.max_purchase != null
                                                                                                            ">
																										{{
																											assignedPart.max_purchase
																										}}
																									</div>
																									<div v-else>None
																									</div>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-flex xs12 class="mt-6">
																							<v-layout row wrap>
																								<v-flex xs12 md4>
																									<b>Date
																										Created: </b>
																								</v-flex>
																								<v-flex xs12 md8>
																									<div>
																										{{
																											assignedPart.created_at | moment("DD/MM/YYYY - hh: mm a")
																										}}
																									</div>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																			</v-layout>
																		</div>
																	</v-flex>
																	<v-flex xs12>
																		<v-layout row wrap class="mt-6">
																			<v-flex xs12 md2>
																				<b>Brief: </b>
																			</v-flex>
																			<v-flex xs12 md10>
																				<div class="mx-1">
																					{{ assignedPart.brief }}
																				</div>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-flex xs12>
																		<v-layout row wrap class="mt-3">
																			<v-flex xs12 md2>
																				<b>Description: </b>
																			</v-flex>
																			<v-flex xs12 md10>
																				<div class="mx-1"
																				     v-html="assignedPart.description">

																				</div>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</v-layout>
															</div>
														</v-flex>

													</v-layout>
												</v-flex>
												<v-flex xs12>

												</v-flex>
											</v-layout>

										</div>
									</v-card-text>
								</div>

								<div v-if="activeTab == 'stocks'">
									<div v-if="stockView == 'default'">
										<v-card elevation="0" class="mt-5">
											<v-flex xs12 class="mt-10 mb-2">
												<div class="mx-5">
													<v-layout row wrap>
														<v-flex xs12 md6>
															<div v-if="stockLength != 0" align="left" class="mt-1">
																<b class="display-1 primary--text">Total: </b>{{
																	stockPagination.total | formatNumber
																}}
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="pa-1">
																<v-text-field class="text_field background" outlined
																              dense
																              rounded label="Search"
																              append-icon="mdi-undo-variant"
																              @click:append="
                                                                        resetStockSearch()
                                                                        " v-on:keyup.enter="stockSearch
        " v-model="stockSearchTerm" @input="
        enableStockSearch()
        "></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md2>
															<div class="pa-1" align="right">
																<v-btn class="button mx-1" icon @click="stockSearch"
																       :loading="stockSearchLoader
                                                                        " :disabled="stockSearchButtonDisabled
        ">

																	<v-icon class="secondary--text">mdi-magnify</v-icon>
																</v-btn>
																<v-btn icon class="button mx-1" @click="
                                                                    changeStockView(
                                                                        'create'
                                                                    )
                                                                    " v-if="$can('stock_create')
        ">
																	<v-icon
																		class="primary--text">
																		mdi-plus-circle-outline
																	</v-icon>
																</v-btn>
															</div>
														</v-flex>
													</v-layout>
												</div>
											</v-flex>
											<v-divider class="mt-9 mx-5"></v-divider>
											<v-flex xs12 class="mt-5">
												<div v-if="stocks.length == 0">
													<v-card elevation="0">
														<v-layout row wrap>
															<v-flex xs12 md1>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			<v-icon large class="text--text">
																				mdi-alert-circle-outline
																			</v-icon>
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
															<v-flex xs12 md11>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			No Stock Found
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
														</v-layout>
													</v-card>
												</div>
												<div v-else>
													<div class="hidden-sm-and-down">
														<v-card elevation="0" class="pa-2">
															<v-simple-table>
																<template v-slot:default>
																	<thead class="header">
																	<tr class="accent">
																		<th class="text-left text--text">Stock No</th>
																		<th class="text-left text--text">Supplier</th>
																		<th class="text-left text--text">
																			<div align="right">
																				Invoice No
																			</div>
																		</th>
																		<th class="text-left text--text">
																			<div align="right">
																				Buying (Kshs)
																			</div>
																		</th>
																		<th class="text-left text--text">
																			<div align="right">
																				Selling (Kshs)
																			</div>
																		</th>
																		<th class="text-left text--text">
																			<div align="right">
																				Initial Stock
																			</div>
																		</th>
																		<th class="text-left text--text">
																			<div align="right">
																				Stock Balance
																			</div>
																		</th>
																		<th class="text-left text--text">
																			<div align="right">
																				Expiry Date
																			</div>
																		</th>
																		<th class="text-left text--text">Date Added
																		</th>
																		<th class="text-left text--text">Status</th>
																		<th class="text-right text--text">Actions</th>
																	</tr>
																	</thead>
																	<tbody>
																	<tr v-for="item in stocks" :key="item.id">
																		<td>
																			{{ item.stock_no }}
																		</td>
																		<td>
																			{{ item.supplier.name }}
																		</td>
																		<td>
																			<div align="right">
																				{{ item.invoice_no }}
																			</div>
																		</td>
																		<td>
																			<div align="right">
																				{{ item.buying_price | formatNumber }}
																			</div>
																		</td>
																		<td>
																			<div align="right">
																				{{ item.selling_price | formatNumber }}
																			</div>
																		</td>
																		<td>
																			<div align="right">
																				{{
																					item.initial_quantity | formatNumber
																				}}
																			</div>
																		</td>
																		<td>
																			<div align="right">
																				{{
																					item.current_quantity | formatNumber
																				}}
																			</div>
																		</td>
																		<td>
																			<div align="right">
																				{{
																					item.expiry_date | moment("DD/MM/YYYY")
																				}}
																			</div>
																		</td>
																		<td>
																			{{
																				item.created_at | moment("DD/MM/YYYY - hh:mm a")
																			}}
																		</td>
																		<td>
																			<div v-if="item.status == 1">
																				Active
																			</div>
																			<div v-else>Inactive</div>
																		</td>
																		<td>
																			<div align="right">
																				<v-btn v-if="$can('stock_edit')" icon
																				       class="button mr-1 success--text"
																				       @click="editStock(item)">
																					<v-icon small> mdi-pencil</v-icon>
																				</v-btn>
																				<v-btn v-if="$can(
                                                                                        'stock_archive'
                                                                                    )
                                                                                        " icon
																				       class="button mr-1 error--text"
																				       @click="showDeleteStock(item)">
																					<v-icon small> mdi-delete</v-icon>
																				</v-btn>
																				<v-tooltip top v-if="$can(
                                                                                        'stock_activate'
                                                                                    ) && item.status == 0
                                                                                        ">
																					<template
																						v-slot:activator="{ on, attrs }">
																						<v-btn icon
																						       class="button mr-1 red--text"
																						       @click="
                                                                                                    activateStock(
                                                                                                        item
                                                                                                    )
                                                                                                    " :loading="activateLoading &&
                                                                                                        stockIndex ==
                                                                                                        item.id
                                                                                                        " v-bind="attrs"
																						       v-on="on">
																							<v-icon small>
																								mdi-toggle-switch-off-outline
																							</v-icon>
																						</v-btn>
																					</template>
																					<span>Activate Stock</span>
																				</v-tooltip>
																				<v-tooltip top v-if="$can(
                                                                                        'stock_deactivate'
                                                                                    ) && item.status == 1
                                                                                        ">
																					<template
																						v-slot:activator="{ on, attrs }">
																						<v-btn icon
																						       class="button mr-1 green--text"
																						       @click="
                                                                                                    deactivateStock(
                                                                                                        item
                                                                                                    )
                                                                                                    " :loading="deactivateLoading &&
                                                                                                        stockIndex ==
                                                                                                        item.id
                                                                                                        " v-bind="attrs"
																						       v-on="on">
																							<v-icon small>
																								mdi-toggle-switch
																							</v-icon>
																						</v-btn>
																					</template>
																					<span>Deactivate Stock</span>
																				</v-tooltip>
																			</div>
																		</td>
																	</tr>
																	</tbody>
																</template>
															</v-simple-table>
														</v-card>
													</div>
													<div class="hidden-md-and-up">
														<div>
															<v-layout column>
																<template v-for="(
                                      stock, index
                                    ) in stocks">
																	<div :key="index">
																		<v-flex xs12 class="mb-2">
																			<v-card elevation="0"
																			        style="border-radius: 25px;"
																			        outlined>
																				<div class="pa-5 mt-2">
																					<v-layout column>
																						<v-flex xs12>
																							<v-layout row wrap>
																								<v-flex xs11>
																									<div
																										class="title primary--text">
																										<b>{{
																												stock.stock_no
																											}}</b>
																									</div>
																								</v-flex>
																								<v-flex xs1>
																									<v-btn depressed
																									       class="red--text"
																									       :loading="loading &&
                                                                                                            stockIndex ==
                                                                                                            stock.id
                                                                                                            " icon
																									       @click="
																												  deleteStock(
																														stock
																												  )
																												  "
																									       v-if="$can(
																															 'stock_archive'
																														)
																														">
																										<v-icon>
																											mdi-delete
																										</v-icon>
																									</v-btn>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-divider
																							class="mt-2"></v-divider>
																						<v-flex xs12 class="mt-1">
																							<v-layout column>
																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Supplier</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												stock.supplier.name
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Invoice
																												No</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												stock.invoice_no
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Buying
																												(Kshs)</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												stock.buying_price | formatNumber
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Selling
																												(Kshs)</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												stock.selling_price | formatNumber
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Initial
																												Stock</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												stock.initial_quantity | formatNumber
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Stock
																												Balance</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												stock.current_quantity | formatNumber
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Expiry
																												Date</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												stock.expiry_date | moment("DD/MM/YYYY")
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>


																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Status</b>
																										</v-flex>
																										<v-flex xs8>
																											<div
																												v-if="stock.status == 1">
																												Active
																											</div>
																											<div v-else>
																												Inactive
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Date
																												Created</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												stock.created_at | moment("DD/MM/YYYY")
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>

																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs12
																										        v-if="$can('stock_edit')">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="blue darken-1 text-none white--text mt-1"
																													block
																													@click="
                                                                                                                        editStock(
                                                                                                                            stock
                                                                                                                        )
                                                                                                                        ">
																													Edit
																													<v-icon
																														right>
																														mdi-pencil
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																										<v-flex xs12
																										        v-if="$can(
        'stock_activate'
    ) && stock.status == 0
        ">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="green darken-1 text-none white--text mt-1"
																													block
																													:loading="activateLoading &&
                                                                                                                        stockIndex ==
                                                                                                                        stock.id
                                                                                                                        "
																													@click="
        activateStock(
            stock
        )
        ">Activate Stock
																													<v-icon
																														right>
																														mdi-toggle-switch
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																										<v-flex xs12
																										        v-if="$can(
        'stock_deactivate'
    ) && stock.status == 1
        ">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="red darken-1 text-none white--text mt-1"
																													block
																													:loading="deactivateLoading &&
                                                                                                                        stockIndex ==
                                                                                                                        stock.id
                                                                                                                        "
																													@click="
        deactivateStock(
            stock
        )
        ">Deactivate Stock
																													<v-icon
																														right>
																														mdi-toggle-switch-off-outline
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</div>
																			</v-card>
																		</v-flex>
																	</div>
																</template>
															</v-layout>
														</div>
													</div>
												</div>
											</v-flex>
										</v-card>
										<v-layout column class="mt-5">
											<v-flex xs12>
												<div align="left">
													<v-pagination v-if="length != 0" :length="stockLength"
													              total-visible="10" v-model="stockPagination.current_page
                                                            " @input="changeStockPage()" circle>
													</v-pagination>
												</div>
											</v-flex>
										</v-layout>
									</div>
									<div v-if="stockView == 'create'">
										<v-flex class="mt-5">
											<v-card-title class="accent text--text">
												<v-spacer></v-spacer>
												<div v-if="newStock.id == null">
													Add Stock
												</div>
												<div v-else>Edit Stock</div>
												<v-spacer></v-spacer>
												<v-btn icon @click="
                                                    changeStockView('default')
                                                    ">
													<v-icon class="text--text"> mdi-close</v-icon>
												</v-btn>
											</v-card-title>
											<v-card-text>
												<v-form v-model="valid" ref="stockForm" lazy-validation>
													<v-layout row wrap class="mt-5">
														<v-flex xs12 md4>
															<div class="pa-3">
																<div>Supplier<span class="error--text">*</span></div>
																<v-select :items="formData.suppliers"
																          v-model="newStock.supplier_id"
																          class="text_field background my-2"
																          item-value="id" item-text="name"
																          outlined dense :rules="inputRules"></v-select>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="pa-3">
																<div>
																	Invoice No<span class="error--text">*</span>
																</div>
																<v-text-field class="text_field background my-2"
																              outlined
																              dense v-model="newStock.invoice_no"
																              :rules="inputRules"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="pa-3">
																<div>
																	Quantity<span class="error--text">*</span>
																</div>
																<v-text-field class="text_field background my-2"
																              outlined
																              dense v-model="newStock.initial_quantity"
																              :rules="inputRules"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md3>
															<div class="pa-3">
																<div>
																	Buying Price<span class="error--text">*</span>
																</div>
																<v-text-field class="text_field background my-2"
																              outlined
																              dense v-model="newStock.buying_price"
																              :rules="inputRules"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md3>
															<div class="pa-3">
																<div>
																	Selling Price<span class="error--text">*</span>
																</div>
																<v-text-field class="text_field background my-2"
																              outlined
																              dense v-model="newStock.selling_price"
																              :rules="inputRules"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md3>
															<div class="pa-3">
																<div>
																	Expiry Date
																</div>
																<v-menu :close-on-content-click="false" max-width="290"
																        transition="scale-transition"
																        v-model="expiryDatePicker" offset-y>
																	<template v-slot:activator="{ on, attrs }">
																		<v-text-field outlined dense
																		              :value="formatExpiryDate"
																		              slot="activator" readonly
																		              v-bind="attrs"
																		              v-on="on"
																		              class="text_field background my-2"></v-text-field>
																	</template>
																	<v-date-picker v-model="newStock.expiry_date"
																	               :min="newStock.manufacture_date"
																	               @change="closeExpiryDate">
																	</v-date-picker>
																</v-menu>
															</div>
														</v-flex>
													</v-layout>
												</v-form>
											</v-card-text>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn depressed class="primary text-none mt-3" @click="saveStock"
												       :disabled="!valid" :loading="loading">
													Save
													<v-icon right> mdi-content-save</v-icon>
												</v-btn>
											</v-card-actions>
										</v-flex>
									</div>
								</div>

								<div v-if="activeTab == 'categories'">
									<div v-if="categoryView == 'default'">
										<v-card elevation="0" class="mt-5">
											<v-flex xs12 class="mt-10 mb-2">
												<div class="mx-5">
													<v-layout row wrap>
														<v-flex xs12 md6>
															<div v-if="categoryLength != 0" align="left" class="mt-1">
																<b class="display-1 primary--text">Total: </b>{{
																	categoryPagination.total | formatNumber
																}}
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="pa-1">
																<v-text-field class="text_field background" outlined
																              dense
																              rounded label="Search"
																              append-icon="mdi-undo-variant"
																              @click:append="
                                                                        resetCategorySearch()
                                                                        " v-on:keyup.enter="categorySearch
        " v-model="categorySearchTerm" @input="
        enableCategorySearch()
        "></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md2>
															<div class="pa-1" align="right">
																<v-btn class="button mx-1" icon @click="categorySearch"
																       :loading="categorySearchLoader
                                                                        " :disabled="categorySearchButtonDisabled
        ">

																	<v-icon class="secondary--text">mdi-magnify</v-icon>
																</v-btn>
																<v-btn v-if="$can('category_create')" icon
																       class="button mx-1" @click="
                                                                    changeCategoryView(
                                                                        'create'
                                                                    )
                                                                    ">
																	<v-icon
																		class="primary--text">
																		mdi-plus-circle-outline
																	</v-icon>
																</v-btn>
															</div>
														</v-flex>
													</v-layout>
												</div>
											</v-flex>
											<v-divider class="mt-9 mx-5"></v-divider>
											<v-flex xs12 class="mt-5">
												<div v-if="categories.length == 0">
													<v-card elevation="0">
														<v-layout row wrap>
															<v-flex xs12 md1>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			<v-icon large class="text--text">
																				mdi-alert-circle-outline
																			</v-icon>
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
															<v-flex xs12 md11>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			No Category Found
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
														</v-layout>
													</v-card>
												</div>
												<div v-else>
													<div class="hidden-sm-and-down">
														<v-card elevation="0" class="pa-2">
															<v-simple-table>
																<template v-slot:default>
																	<thead class="header">
																	<tr class="accent">
																		<th class="text-left text--text">Category</th>
																		<th class="text-left text--text">
																			Sub Category
																		</th>

																		<th class="text-left text--text">Status</th>
																		<th class="text-right text--text">Actions</th>
																	</tr>
																	</thead>
																	<tbody>
																	<tr v-for="item in categories" :key="item.id">
																		<td>
																			{{ item.category.name }}
																		</td>
																		<td>
																			{{ item.sub_category.name }}
																		</td>
																		<td>
																			<div v-if="item.status == 1">
																				Active
																			</div>
																			<div v-else>Inactive</div>
																		</td>
																		<td>
																			<div align="right">
																				<v-btn icon v-if="$can('category_edit')"
																				       class="button mr-1 success--text"
																				       @click="editCategory(item)">
																					<v-icon small> mdi-pencil</v-icon>
																				</v-btn>
																				<v-btn v-if="$can('category_archive')"
																				       icon
																				       class="button mr-1 error--text"
																				       @click="
                                                                                        deleteCategory(
                                                                                            item
                                                                                        )
                                                                                        " :loading="loading &&
                                                                                        categoryIndex ==
                                                                                        item.id
                                                                                        ">
																					<v-icon small> mdi-delete</v-icon>
																				</v-btn>
																				<v-tooltip top v-if="$can('category_activate') && item.status == 0
                                                                                        ">
																					<template
																						v-slot:activator="{ on, attrs }">
																						<v-btn icon
																						       class="button mr-1 red--text"
																						       @click="
                                                                                                    activateCategory(
                                                                                                        item
                                                                                                    )
                                                                                                    " :loading="activateLoading &&
                                                                                                        categoryIndex ==
                                                                                                        item.id
                                                                                                        " v-bind="attrs"
																						       v-on="on">
																							<v-icon small>
																								mdi-toggle-switch-off-outline
																							</v-icon>
																						</v-btn>
																					</template>
																					<span>Activate Category</span>
																				</v-tooltip>
																				<v-tooltip top v-if="$can('category_deactivate') && item.status == 1
                                                                                        ">
																					<template
																						v-slot:activator="{ on, attrs }">
																						<v-btn icon
																						       class="button mr-1 green--text"
																						       @click="
                                                                                                    deactivateCategory(
                                                                                                        item
                                                                                                    )
                                                                                                    " :loading="deactivateLoading &&
                                                                                                        categoryIndex ==
                                                                                                        item.id
                                                                                                        " v-bind="attrs"
																						       v-on="on">
																							<v-icon small>
																								mdi-toggle-switch
																							</v-icon>
																						</v-btn>
																					</template>
																					<span>Deactivate Category</span>
																				</v-tooltip>
																			</div>
																		</td>
																	</tr>
																	</tbody>
																</template>
															</v-simple-table>
														</v-card>
													</div>
													<div class="hidden-md-and-up">
														<div>
															<v-layout column>
																<template v-for="(
                                                                    category, index
                                                                    ) in categories">
																	<div :key="index">
																		<v-flex xs12 class="mb-2">
																			<v-card elevation="0"
																			        style="border-radius: 25px;"
																			        outlined>
																				<div class="pa-5 mt-2">
																					<v-layout column>
																						<v-flex xs12>
																							<v-layout row wrap>
																								<v-flex xs11>
																									<div
																										class="title primary--text">
																										<b>{{
																												category.category.name
																											}}</b>
																									</div>
																								</v-flex>
																								<v-flex xs1>
																									<v-btn depressed
																									       v-if="$can('category_archive')"
																									       class="red--text"
																									       :loading="loading &&
                                                                                                            categoryIndex ==
                                                                                                            category.id
                                                                                                            " icon
																									       @click="
                                                                                                                deleteCategory(
                                                                                                                    category
                                                                                                                )
                                                                                                                ">
																										<v-icon>
																											mdi-delete
																										</v-icon>
																									</v-btn>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-divider
																							class="mt-2"></v-divider>
																						<v-flex xs12 class="mt-1">
																							<v-layout column>
																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Sub
																												Category</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												category.sub_category.name
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Status</b>
																										</v-flex>
																										<v-flex xs8>
																											<div v-if="category.status ==
                                                                                                                1
                                                                                                                ">
																												Active
																											</div>
																											<div v-else>
																												Inactive
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Date
																												Created</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												category.created_at | moment("DD/MM/YYYY")
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>

																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs12
																										        v-if="$can('category_activate') && category.status == 0
        ">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="green darken-1 text-none white--text mt-1"
																													block
																													:loading="activateLoading &&
                                                                                                                        stockIndex ==
                                                                                                                        category.id
                                                                                                                        "
																													@click="
        activateCategory(
            category
        )
        ">Activate Category
																													<v-icon
																														right>
																														mdi-toggle-switch
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																										<v-flex xs12
																										        v-if="$can('category_deactivate') && category.status == 1
        ">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="red darken-1 text-none white--text mt-1"
																													block
																													:loading="deactivateLoading &&
                                                                                                                        stockIndex ==
                                                                                                                        category.id
                                                                                                                        "
																													@click="
        deactivateCategory(
            category
        )
        ">Deactivate Category
																													<v-icon
																														right>
																														mdi-toggle-switch-off-outline
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</div>
																			</v-card>
																		</v-flex>
																	</div>
																</template>
															</v-layout>
														</div>
													</div>
												</div>
											</v-flex>
										</v-card>
										<v-layout column class="mt-5">
											<v-flex xs12>
												<div align="left">
													<v-pagination v-if="length != 0" :length="categoryLength"
													              total-visible="10" v-model="categoryPagination.current_page
                                                            " @input="changeCategoryPage()" circle>
													</v-pagination>
												</div>
											</v-flex>
										</v-layout>
									</div>
									<div v-if="categoryView == 'create'">
										<v-flex class="mt-5">
											<v-card-title class="accent text--text">
												<v-spacer></v-spacer>
												<div v-if="newCategory.id == null">
													Add Category
												</div>
												<div v-else>Edit Category</div>
												<v-spacer></v-spacer>
												<v-btn icon @click="
                                                    changeCategoryView('default')
                                                    ">
													<v-icon class="text--text"> mdi-close</v-icon>
												</v-btn>
											</v-card-title>
											<v-card-text>
												<v-form v-model="valid" ref="categoryForm" lazy-validation>
													<v-layout row wrap class="mt-5">
														<v-flex xs12 md4>
															<div class="pa-3">
																<div>Category<span class="error--text">*</span></div>
																<v-select
																	:items="formData.categories"
																	v-model="newCategory.category_id"
																	class="text_field background my-2"
																	item-value="id"
																	item-text="name"
																	outlined
																	dense
																	:rules="inputRules"
																	@input="populateSubCategories"
																></v-select>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="pa-3">
																<div>Sub-Category<span class="error--text">*</span>
																</div>
																<v-select
																	:items="subCategories"
																	v-model="newCategory.sub_category_id"
																	class="text_field background my-2"
																	item-value="id"
																	item-text="name"
																	outlined
																	dense
																	:rules="inputRules"
																></v-select>
															</div>
														</v-flex>
													</v-layout>
												</v-form>
											</v-card-text>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn depressed class="primary text-none mt-3" @click="saveCategory"
												       :disabled="!valid" :loading="loading">
													Save
													<v-icon right> mdi-content-save</v-icon>
												</v-btn>
											</v-card-actions>
										</v-flex>
									</div>
								</div>

								<div v-if="activeTab == 'levels'">
									<div v-if="levelView == 'default'">
										<v-card elevation="0" class="mt-5">
											<v-flex xs12 class="mt-10 mb-2">
												<div class="mx-5">
													<v-layout row wrap>
														<v-flex xs12 md6>
															<div v-if="levelLength != 0" align="left" class="mt-1">
																<b class="display-1 primary--text">Total: </b>{{
																	levelPagination.total | formatNumber
																}}
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="pa-1">
																<v-text-field class="text_field background" outlined
																              dense
																              rounded label="Search"
																              append-icon="mdi-undo-variant"
																              @click:append="
                                                                        resetLevelSearch()
                                                                        " v-on:keyup.enter="levelSearch
        " v-model="levelSearchTerm" @input="
        enableLevelSearch()
        "></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md2>
															<div class="pa-1" align="right">
																<v-btn class="button mx-1" icon @click="levelSearch"
																       :loading="levelSearchLoader
                                                                        " :disabled="levelSearchButtonDisabled
        ">

																	<v-icon class="secondary--text">mdi-magnify</v-icon>
																</v-btn>
																<v-btn v-if="$can('level_create')" icon
																       class="button mx-1" @click="
                                                                    changeLevelView(
                                                                        'create'
                                                                    )
                                                                    ">
																	<v-icon
																		class="primary--text">
																		mdi-plus-circle-outline
																	</v-icon>
																</v-btn>
															</div>
														</v-flex>
													</v-layout>
												</div>
											</v-flex>
											<v-divider class="mt-9 mx-5"></v-divider>
											<v-flex xs12 class="mt-5">
												<div v-if="levels.length == 0">
													<v-card elevation="0">
														<v-layout row wrap>
															<v-flex xs12 md1>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			<v-icon large class="text--text">
																				mdi-alert-circle-outline
																			</v-icon>
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
															<v-flex xs12 md11>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			No Level Found
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
														</v-layout>
													</v-card>
												</div>
												<div v-else>
													<div class="hidden-sm-and-down">
														<v-card elevation="0" class="pa-2">
															<v-simple-table>
																<template v-slot:default>
																	<thead class="header">
																	<tr class="accent">
																		<th class="text-left text--text">Level</th>
																		<th class="text-left text--text">
																			Sub Level
																		</th>

																		<th class="text-left text--text">Status</th>
																		<th class="text-right text--text">Actions</th>
																	</tr>
																	</thead>
																	<tbody>
																	<tr v-for="item in levels" :key="item.id">
																		<td>
																			{{ item.level.name }}
																		</td>
																		<td>
																			{{ item.sub_level.name }}
																		</td>
																		<td>
																			<div v-if="item.status == 1">
																				Active
																			</div>
																			<div v-else>Inactive</div>
																		</td>
																		<td>
																			<div align="right">
																				<v-btn icon
																				       v-if="$can('level_edit')"
																				       class="button mr-1 success--text"
																				       @click="editLevel(item)">
																					<v-icon small> mdi-pencil</v-icon>
																				</v-btn>
																				<v-btn
																					v-if="$can('level_archive')"
																					icon
																					class="button mr-1 error--text"
																					@click="
                                                                                        deleteLevel(
                                                                                            item
                                                                                        )
                                                                                        " :loading="loading &&
                                                                                        levelIndex ==
                                                                                        item.id
                                                                                        ">
																					<v-icon small> mdi-delete</v-icon>
																				</v-btn>
																				<v-tooltip top v-if="$can('level_activate') && item.status == 0
                                                                                        ">
																					<template
																						v-slot:activator="{ on, attrs }">
																						<v-btn icon
																						       class="button mr-1 red--text"
																						       @click="
                                                                                                    activateLevel(
                                                                                                        item
                                                                                                    )
                                                                                                    " :loading="activateLoading &&
                                                                                                        levelIndex ==
                                                                                                        item.id
                                                                                                        " v-bind="attrs"
																						       v-on="on">
																							<v-icon small>
																								mdi-toggle-switch-off-outline
																							</v-icon>
																						</v-btn>
																					</template>
																					<span>Activate Level</span>
																				</v-tooltip>
																				<v-tooltip top v-if="$can('level_deactivate') && item.status == 1
                                                                                        ">
																					<template
																						v-slot:activator="{ on, attrs }">
																						<v-btn icon
																						       class="button mr-1 green--text"
																						       @click="
                                                                                                    deactivateLevel(
                                                                                                        item
                                                                                                    )
                                                                                                    " :loading="deactivateLoading &&
                                                                                                    levelIndex ==
                                                                                                        item.id
                                                                                                        " v-bind="attrs"
																						       v-on="on">
																							<v-icon small>
																								mdi-toggle-switch
																							</v-icon>
																						</v-btn>
																					</template>
																					<span>Deactivate Level</span>
																				</v-tooltip>
																			</div>
																		</td>
																	</tr>
																	</tbody>
																</template>
															</v-simple-table>
														</v-card>
													</div>
													<div class="hidden-md-and-up">
														<div>
															<v-layout column>
																<template v-for="(
                                                                    level, index
                                                                    ) in levels">
																	<div :key="index">
																		<v-flex xs12 class="mb-2">
																			<v-card elevation="0"
																			        style="border-radius: 25px;"
																			        outlined>
																				<div class="pa-5 mt-2">
																					<v-layout column>
																						<v-flex xs12>
																							<v-layout row wrap>
																								<v-flex xs11>
																									<div
																										class="title primary--text">
																										<b>{{
																												level.sub_level.name
																											}}</b>
																									</div>
																								</v-flex>
																								<v-flex xs1>
																									<v-btn depressed
																									       v-if="$can('level_archive')"
																									       class="red--text"
																									       :loading="loading &&
                                                                                                            levelIndex ==
                                                                                                            level.id
                                                                                                            " icon
																									       @click="
                                                                                                                deleteLevel(
                                                                                                                    level
                                                                                                                )
                                                                                                                ">
																										<v-icon>
																											mdi-delete
																										</v-icon>
																									</v-btn>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-divider
																							class="mt-2"></v-divider>
																						<v-flex xs12 class="mt-1">
																							<v-layout column>
																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Level</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												level.level.name
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Status</b>
																										</v-flex>
																										<v-flex xs8>
																											<div v-if="level.status ==
                                                                                                                1
                                                                                                                ">
																												Active
																											</div>
																											<div v-else>
																												Inactive
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Date
																												Created</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												level.created_at | moment("DD/MM/YYYY")
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>

																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs12>
																											<div>
																												<v-btn
																													v-if="$can('level_edit')"
																													rounded
																													depressed
																													class="blue darken-1 text-none white--text mt-1"
																													block
																													@click="
                                                                                                                        editLevel(
                                                                                                                            level
                                                                                                                        )
                                                                                                                        ">
																													Edit
																													<v-icon
																														right>
																														mdi-pencil
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																										<v-flex xs12
																										        v-if="$can('level_activate') && level.status == 0
        ">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="green darken-1 text-none white--text mt-1"
																													block
																													:loading="activateLoading &&
                                                                                                                        levelIndex ==
                                                                                                                        level.id
                                                                                                                        "
																													@click="
        activateLevel(
            level
        )
        ">Activate Level
																													<v-icon
																														right>
																														mdi-toggle-switch
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																										<v-flex xs12
																										        v-if="$can('level_deactivate') && level.status == 1
        ">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="red darken-1 text-none white--text mt-1"
																													block
																													:loading="deactivateLoading &&
                                                                                                                        levelIndex ==
                                                                                                                        level.id
                                                                                                                        "
																													@click="
        deactivateLevel(
            level
        )
        ">Deactivate Level
																													<v-icon
																														right>
																														mdi-toggle-switch-off-outline
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</div>
																			</v-card>
																		</v-flex>
																	</div>
																</template>
															</v-layout>
														</div>
													</div>
												</div>
											</v-flex>
										</v-card>
										<v-layout column class="mt-5">
											<v-flex xs12>
												<div align="left">
													<v-pagination v-if="length != 0" :length="levelLength"
													              total-visible="10" v-model="levelPagination.current_page
                                                            " @input="changeLevelPage()" circle>
													</v-pagination>
												</div>
											</v-flex>
										</v-layout>
									</div>
									<div v-if="levelView == 'create'">
										<v-flex class="mt-5">
											<v-card-title class="accent text--text">
												<v-spacer></v-spacer>
												<div v-if="newLevel.id == null">
													Add Level
												</div>
												<div v-else>Edit Level</div>
												<v-spacer></v-spacer>
												<v-btn icon @click="
                                                    changeLevelView('default')
                                                    ">
													<v-icon class="text--text"> mdi-close</v-icon>
												</v-btn>
											</v-card-title>
											<v-card-text>
												<v-form v-model="valid" ref="levelForm" lazy-validation>
													<v-layout row wrap class="mt-5">
														<v-flex xs12 md4>
															<div class="pa-3">
																<div>Level<span class="error--text">*</span></div>
																<v-select
																	:items="formData.levels"
																	v-model="newLevel.level_id"
																	class="text_field background my-2"
																	item-value="id"
																	item-text="name"
																	outlined
																	dense
																	:rules="inputRules"
																	@input="populateSubLevels"
																></v-select>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="pa-3">
																<div>Sub-Level<span class="error--text">*</span></div>
																<v-select
																	:items="subLevels"
																	v-model="newLevel.sub_level_id"
																	class="text_field background my-2"
																	item-value="id"
																	item-text="name"
																	outlined
																	dense
																	:rules="inputRules"
																></v-select>
															</div>
														</v-flex>
													</v-layout>
												</v-form>
											</v-card-text>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn depressed class="primary text-none mt-3" @click="saveLevel"
												       :disabled="!valid" :loading="loading">
													Save
													<v-icon right> mdi-content-save</v-icon>
												</v-btn>
											</v-card-actions>
										</v-flex>
									</div>
								</div>

								<div v-if="activeTab == 'learningAreas'">
									<div v-if="learningAreaView == 'default'">
										<v-card elevation="0" class="mt-5">
											<v-flex xs12 class="mt-10 mb-2">
												<div class="mx-5">
													<v-layout row wrap>
														<v-flex xs12 md6>
															<div v-if="learningAreaLength != 0" align="left"
															     class="mt-1">
																<b class="display-1 primary--text">Total: </b>{{
																	learningAreaPagination.total | formatNumber
																}}
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="pa-1">
																<v-text-field class="text_field background" outlined
																              dense
																              rounded label="Search"
																              append-icon="mdi-undo-variant"
																              @click:append="
                                                                        resetLearningAreaSearch()
                                                                        " v-on:keyup.enter="learningAreaSearch
        " v-model="learningAreaSearchTerm" @input="
        enableLearningAreaSearch()
        "></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md2>
															<div class="pa-1" align="right">
																<v-btn class="button mx-1" icon
																       @click="learningAreaSearch"
																       :loading="learningAreaSearchLoader
                                                                        " :disabled="learningAreaSearchButtonDisabled
        ">

																	<v-icon class="secondary--text">mdi-magnify</v-icon>
																</v-btn>
																<v-btn icon class="button mx-1"
																       v-if="$can('learning_area_create')" @click="
                                                                    changeLearningAreaView(
                                                                        'create'
                                                                    )
                                                                    ">
																	<v-icon
																		class="primary--text">
																		mdi-plus-circle-outline
																	</v-icon>
																</v-btn>
															</div>
														</v-flex>
													</v-layout>
												</div>
											</v-flex>
											<v-divider class="mt-9 mx-5"></v-divider>
											<v-flex xs12 class="mt-5">
												<div v-if="learningAreas.length == 0">
													<v-card elevation="0">
														<v-layout row wrap>
															<v-flex xs12 md1>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			<v-icon large class="text--text">
																				mdi-alert-circle-outline
																			</v-icon>
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
															<v-flex xs12 md11>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			No Learning Area Found
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
														</v-layout>
													</v-card>
												</div>
												<div v-else>
													<div class="hidden-sm-and-down">
														<v-card elevation="0" class="pa-2">
															<v-simple-table>
																<template v-slot:default>
																	<thead class="header">
																	<tr class="accent">
																		<th class="text-left text--text">Learning Area
																		</th>
																		<th class="text-left text--text">Status</th>
																		<th class="text-right text--text">Actions</th>
																	</tr>
																	</thead>
																	<tbody>
																	<tr v-for="item in learningAreas" :key="item.id">
																		<td>
																			{{ item.learning_area.name }}
																		</td>
																		<td>
																			<div v-if="item.status == 1">
																				Active
																			</div>
																			<div v-else>Inactive</div>
																		</td>
																		<td>
																			<div align="right">
																				<v-btn icon
																				       v-if="$can('learning_area_edit')"
																				       class="button mr-1 success--text"
																				       @click="editLearningArea(item)">
																					<v-icon small> mdi-pencil</v-icon>
																				</v-btn>
																				<v-btn
																					v-if="$can('learning_area_archive')"
																					icon
																					class="button mr-1 error--text"
																					@click="
                                                                                        deleteLearningArea(
                                                                                            item
                                                                                        )
                                                                                        " :loading="loading &&
                                                                                        learningAreaIndex ==
                                                                                        item.id
                                                                                        ">
																					<v-icon small> mdi-delete</v-icon>
																				</v-btn>
																				<v-tooltip top v-if="$can('learning_area_activate') && item.status == 0
                                                                                        ">
																					<template
																						v-slot:activator="{ on, attrs }">
																						<v-btn icon
																						       class="button mr-1 red--text"
																						       @click="
                                                                                                    activateLearningArea(
                                                                                                        item
                                                                                                    )
                                                                                                    " :loading="activateLoading &&
                                                                                                        learningAreaIndex ==
                                                                                                        item.id
                                                                                                        " v-bind="attrs"
																						       v-on="on">
																							<v-icon small>
																								mdi-toggle-switch-off-outline
																							</v-icon>
																						</v-btn>
																					</template>
																					<span>Activate Learning Area</span>
																				</v-tooltip>
																				<v-tooltip top v-if="$can('learning_area_deactivate') && item.status == 1
                                                                                        ">
																					<template
																						v-slot:activator="{ on, attrs }">
																						<v-btn icon
																						       class="button mr-1 green--text"
																						       @click="
                                                                                                    deactivateLearningArea(
                                                                                                        item
                                                                                                    )
                                                                                                    " :loading="deactivateLoading &&
                                                                                                    learningAreaIndex ==
                                                                                                        item.id
                                                                                                        " v-bind="attrs"
																						       v-on="on">
																							<v-icon small>
																								mdi-toggle-switch
																							</v-icon>
																						</v-btn>
																					</template>
																					<span>Deactivate Learning Area</span>
																				</v-tooltip>
																			</div>
																		</td>
																	</tr>
																	</tbody>
																</template>
															</v-simple-table>
														</v-card>
													</div>
													<div class="hidden-md-and-up">
														<div>
															<v-layout column>
																<template v-for="(
                                                                    learningArea, index
                                                                    ) in learningAreas">
																	<div :key="index">
																		<v-flex xs12 class="mb-2">
																			<v-card elevation="0"
																			        style="border-radius: 25px;"
																			        outlined>
																				<div class="pa-5 mt-2">
																					<v-layout column>
																						<v-flex xs12>
																							<v-layout row wrap>
																								<v-flex xs11>
																									<div
																										class="title primary--text">
																										<b>{{
																												learningArea.learning_area.name
																											}}</b>
																									</div>
																								</v-flex>
																								<v-flex xs1>
																									<v-btn depressed
																									       v-if="$can('learning_area_archive')"
																									       class="red--text"
																									       :loading="loading &&
                                                                                                            learningAreaIndex ==
                                                                                                            learningArea.id
                                                                                                            " icon
																									       @click="
                                                                                                                deleteLearningArea(
                                                                                                                    learningArea
                                                                                                                )
                                                                                                                ">
																										<v-icon>
																											mdi-delete
																										</v-icon>
																									</v-btn>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-divider
																							class="mt-2"></v-divider>
																						<v-flex xs12 class="mt-1">
																							<v-layout column>
																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Status</b>
																										</v-flex>
																										<v-flex xs8>
																											<div v-if="learningArea.status ==
                                                                                                                1
                                                                                                                ">
																												Active
																											</div>
																											<div v-else>
																												Inactive
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<!-- <v-flex xs12 class="mt-5">
                                                                                                    <v-layout row wrap>
                                                                                                        <v-flex xs4>
                                                                                                            <b>Date
                                                                                                                Created</b>
                                                                                                        </v-flex>
                                                                                                        <v-flex xs8>
                                                                                                            {{ learningArea.created_at | moment("DD/MM/YYYY") }}
                                                                                                        </v-flex>
                                                                                                    </v-layout>
                                                                                                </v-flex> -->

																								<v-flex xs12
																								        class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs12>
																											<div>
																												<v-btn
																													v-if="$can('learning_area_edit')"
																													rounded
																													depressed
																													class="blue darken-1 text-none white--text mt-1"
																													block
																													@click="
                                                                                                                        editLearningArea(
                                                                                                                            learningArea
                                                                                                                        )
                                                                                                                        ">
																													Edit
																													<v-icon
																														right>
																														mdi-pencil
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																										<v-flex xs12
																										        v-if="$can('learning_area_activate') && learningArea.status == 0
        ">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="green darken-1 text-none white--text mt-1"
																													block
																													:loading="activateLoading &&
                                                                                                                        learningAreaIndex ==
                                                                                                                        learningArea.id
                                                                                                                        "
																													@click="
        activateLearningArea(
            learningArea
        )
        ">Activate Learning Area
																													<v-icon
																														right>
																														mdi-toggle-switch
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																										<v-flex xs12
																										        v-if="$can('learning_area_deactivate') && learningArea.status == 1
        ">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="red darken-1 text-none white--text mt-1"
																													block
																													:loading="deactivateLoading &&
                                                                                                                        learningAreaIndex ==
                                                                                                                        learningArea.id
                                                                                                                        "
																													@click="
        deactivateLearningArea(
            learningArea
        )
        ">Deactivate Learning Area
																													<v-icon
																														right>
																														mdi-toggle-switch-off-outline
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</div>
																			</v-card>
																		</v-flex>
																	</div>
																</template>
															</v-layout>
														</div>
													</div>
												</div>
											</v-flex>
										</v-card>
										<v-layout column class="mt-5">
											<v-flex xs12>
												<div align="left">
													<v-pagination v-if="length != 0" :length="learningAreaLength"
													              total-visible="10" v-model="learningAreaPagination.current_page
                                                            " @input="changeLearningAreaPage()" circle>
													</v-pagination>
												</div>
											</v-flex>
										</v-layout>
									</div>
									<div v-if="learningAreaView == 'create'">
										<v-flex class="mt-5">
											<v-card-title class="accent text--text">
												<v-spacer></v-spacer>
												<div v-if="newLearningArea.id == null">
													Add Learning Area
												</div>
												<div v-else>Edit Learning Area</div>
												<v-spacer></v-spacer>
												<v-btn icon @click="
                                                    changeLearningAreaView('default')
                                                    ">
													<v-icon class="text--text"> mdi-close</v-icon>
												</v-btn>
											</v-card-title>
											<v-card-text>
												<v-form v-model="valid" ref="learningAreaForm" lazy-validation>
													<v-layout row wrap class="mt-5">
														<v-flex xs12 md4>
															<div class="pa-3">
																<div>Learning Area<span class="error--text">*</span>
																</div>
																<v-select
																	:items="formData.learningAreas"
																	v-model="newLearningArea.learning_area_id"
																	class="text_field background my-2"
																	item-value="id"
																	item-text="name"
																	outlined
																	dense
																	:rules="inputRules"
																></v-select>
															</div>
														</v-flex>
													</v-layout>
												</v-form>
											</v-card-text>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn depressed class="primary text-none mt-3"
												       @click="saveLearningArea"
												       :disabled="!valid" :loading="loading">
													Save
													<v-icon right> mdi-content-save</v-icon>
												</v-btn>
											</v-card-actions>
										</v-flex>
									</div>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</div>
				<div v-if="view == 'delete-stock'">
					<v-card outlined class="mt-5">
						<v-card-title class="accent text--text">
							<v-spacer></v-spacer>
							<div>Request Stock Discard / Return</div>
							<v-spacer></v-spacer>
							<v-btn icon @click="changeView('show')">
								<v-icon> mdi-close</v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text>
							<v-flex class="mt-5">
								<v-card-text>
									<v-form v-model="valid" ref="stockForm" lazy-validation>
										<v-layout wrap class="mt-5 d-flex flex-column">
											<v-flex xs12 md6>
												<div class="pa-3 pb-0">
													<div>
														Quantity<span class="error--text">*</span>
													</div>
													<v-text-field class="text_field background my-2"
													              outlined
													              dense v-model="stockDiscard.quantity"
													              :rules="inputRules"></v-text-field>
												</div>
											</v-flex>
											<v-flex xs12 md12>
												<div class="pa-3 pb-0">
													<div>
														Action
													</div>
													<v-radio-group inline v-model="stockDiscard.action">
														<v-radio label="Discard Stock" value="discarded"></v-radio>
														<v-radio label="Return to supplier" value="returned"></v-radio>
													</v-radio-group>
												</div>
											</v-flex>
											<v-flex xs12 md6>
												<div class="pa-3 pb-0">
													<div>
														Reason for {{
															stockDiscard.action === "discarded" ? "discarding" : "returning"
														}}
														<span class="error--text">*</span>
													</div>
													<v-text-field class="text_field background my-2" outlined
													              :rules="inputRules" dense
													              v-model="stockDiscard.reason">
													</v-text-field>
												</div>
											</v-flex>
											<v-flex xs12 md4>
												<div class="pa-3">
													<v-btn depressed class="secondary text-none white--text"
													       @click="initiateDiscardStock"
													       :disabled="
                         !valid
                         || isNaN(stockDiscard.quantity)
                         || stockDiscard.quantity > assignedStock.current_quantity"
													       :loading="loading">
														Request
														{{ stockDiscard.action === "discarded" ? "Discard" : "Return" }}
														<v-icon right> mdi-trash-can</v-icon>
													</v-btn>
												</div>
											</v-flex>

										</v-layout>
									</v-form>
								</v-card-text>
							</v-flex>
						</v-card-text>
					</v-card>
				</div>
			</v-layout>
		</div>
		<logData v-if="logDataModel" v-model="logDataModel"/>
	</div>
</template>
<style>
.ck-editor__editable {
	min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";

Vue.use(CKEditor);

export default {
	components: {
		logData,
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,

			editor: ClassicEditor,
			editorConfig: {},

			importDialog: false,
			activeTab: "details",

			view: "default",
			stockView: "default",
			categoryView: "default",
			levelView: "default",
			learningAreaView: "default",

			contactView: "default",
			loading: false,
			stockLoader: false,
			categoryLoader: false,
			levelLoader: false,
			learningAreaLoader: false,

			activateLoading: false,
			deactivateLoading: false,

			valid: true,
			message: "",
			color: "",
			delete: false,
			activate: false,
			confirm: false,
			tab: null,

			expiryDatePicker: false,

			partTab: null,
			stockIndex: null,
			categoryIndex: null,
			levelIndex: null,
			learningAreaIndex: null,
			partIndex: null,

			snackbar: false,
			inputRules: [(v) => !!v || "Input is required"],

			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],
			monthNames: [
				{ name: "January" },
				{ name: "February" },
				{ name: "March" },
				{ name: "April" },
				{ name: "May" },
				{ name: "June" },
				{ name: "July" },
				{ name: "August" },
				{ name: "September" },
				{ name: "October" },
				{ name: "November" },
				{ name: "December" },
			],

			emailRules: [
				(v) =>
					!v ||
					/^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
						v
					) ||
					"E-mail must be valid",
				(v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
			],
			websiteRules: [
				(v) =>
					!v ||
					/^[a-zA-Z0-9\.!#:$%&'*+/=?^_~-]+[a-zA-Z]{2,3}$/.test(v) ||
					"URL must be valid",
				(v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
			],

			items: [],
			searchParts: null,

			searchTerm: null,
			stockSearchTerm: null,
			categorySearchTerm: null,
			levelSearchTerm: null,
			learningAreaSearchTerm: null,

			searchLoader: false,
			searchButtonDisabled: false,
			stockSearchButtonDisabled: false,
			categorySearchButtonDisabled: false,
			levelSearchButtonDisabled: false,
			learningAreaSearchButtonDisabled: false,

			filteredParts: false,
			filteredCategories: false,
			filteredLevels: false,
			filteredLearningAreas: false,

			purchaseRestrictions: false,

			years: [],
			newPart: {
				id: null,
				name: null,
				brief: null,
				serial_no: null,
				description: null,
				max_purchase: null,
			},
			newStock: {
				id: null,
				supplier_id: null,
				part_id: null,
				invoice_no: null,
				initial_quantity: null,
				buying_price: null,
				selling_price: null,
				expiry_date: null,
			},
			newCategory: {
				id: null,
				part_id: null,
				category_id: null,
				sub_category_id: null,
			},
			newLevel: {
				id: null,
				part_id: null,
				level_id: null,
				sub_level_id: null,
			},
			newLearningArea: {
				id: null,
				part_id: null,
				learning_area_id: null,
			},

			assignedPart: null,
			assignedStock: null,
			formData: null,

			stockDiscard: {
				quantity: null,
				action: "discarded",
				reason: ""
			},

			coverImageDialog: false,
			imageDialog: false,
			imageLoading: false,
			fileRules: [(v) => !!v || this.$t("kindlySelectAFileMsg")],
			imageFile: null,
			imageValid: true,

			stockSearchLoader: false,
			categorySearchLoader: false,
			levelSearchLoader: false,
			learningAreaSearchLoader: false,

			stocks: [],
			categories: [],
			levels: [],
			learningAreas: [],

			stockPagination: {
				search: " ",
				current_page: 1,
				per_page: 0,
				total: 0,
				visible: 10,
			},
			categoryPagination: {
				search: " ",
				current_page: 1,
				per_page: 0,
				total: 0,
				visible: 10,
			},
			levelPagination: {
				search: " ",
				current_page: 1,
				per_page: 0,
				total: 0,
				visible: 10,
			},
			learningAreaPagination: {
				search: " ",
				current_page: 1,
				per_page: 0,
				total: 0,
				visible: 10,
			},


			logData: {
				message: null,
				model: null,
			},

			subCategories: [],
			subLevels: [],
		};
	},
	watch: {
		searchParts(val) {
			val && val !== this.newStock.user_id && this.findPart(val);
		},
	},
	created() {
		if (this.$can("part_view")) {
			this.startPartLoader();
			this.fetchParts(this.partPagination.current_page);
			this.getFormData();
			// this.populateYears();
		}
	},
	methods: {
		...mapActions([
			"fetchParts",
			"startPartLoader",
			"stopPartLoader",
			"filterParts",

			"filterLogData",
			"openLogDataModel",
		]),
		closeExpiryDate() {
			this.expiryDatePicker = false;
		},
		populateYears() {
			var max = new Date().getFullYear();
			var min = max - 100;
			var years = [];

			for (var i = max; i >= min; i--) {
				years.push(i);
			}
			this.years = years;
		},
		populateSubCategories() {
			this.subCategories = []
			for (var x = 0; x < this.formData.categories.length; x++) {
				if (this.formData.categories[x].id == this.newCategory.category_id) {
					this.subCategories = this.formData.categories[x].sub_categories
				}
			}
		},
		populateSubLevels() {
			this.subLevels = []
			for (var x = 0; x < this.formData.levels.length; x++) {
				if (this.formData.levels[x].id == this.newLevel.level_id) {
					this.subLevels = this.formData.levels[x].sub_levels
				}
			}
		},
		findPart(v) {
			this.loading = true;

			setTimeout(() => {
				apiCall({
					url: "/api/user?type=search&search=" + this.searchParts,
					method: "GET",
				})
					.then((resp) => {
						this.items = resp.data;
					})
					.catch((error) => {
						this.loading = false;
					});

				this.loading = false;
			}, 3000);
		},
		enableSearch() {
			this.searchButtonDisabled = false;
		},
		enableStockSearch() {
			this.stockSearchButtonDisabled = false;
		},
		enableCategorySearch() {
			this.categorySearchButtonDisabled = false;
		},
		enableLevelSearch() {
			this.levelSearchButtonDisabled = false;
		},
		enableLearningAreaSearch() {
			this.learningAreaSearchButtonDisabled = false;
		},

		resetSearch() {
			this.searchTerm = null;
			this.filteredParts = true;
			this.startPartLoader();
			this.fetchParts(1);
		},
		resetStockSearch() {
			this.stockSearchTerm = null;
			this.filteredStocks = false;
			this.stockLoader = false;
			this.changeStockPage();
		},
		resetCategorySearch() {
			this.categorySearchTerm = null;
			this.filteredCategories = false;
			this.categoryLoader = false;
			this.changeCategoryPage();
		},
		resetLevelSearch() {
			this.levelSearchTerm = null;
			this.filteredLevels = false;
			this.levelLoader = false;
			this.changeLevelPage();
		},
		resetLearningAreaSearch() {
			this.learningAreaSearchTerm = null;
			this.filteredLearningAreas = false;
			this.learningAreaLoader = false;
			this.changeLearningAreaPage();
		},

		changeStockPage() {
			this.stockLoader = true;
			if (this.filteredStocks == true) {
				this.stockSearchButtonDisabled = false;
				apiCall({
					url:
						"/api/stock?part_id=" +
						this.assignedPart.id +
						"&page=" +
						this.stockPagination.current_page +
						"&type=search&search=" +
						this.stockSearchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.stockLoader = false;

						this.stocks = resp.data;
						this.stockPagination.current_page =
							resp.current_page;
						this.stockPagination.total = resp.total;
						this.stockPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.stockLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				apiCall({
					url:
						"/api/stock?part_id=" +
						this.assignedPart.id +
						"&page=" +
						this.stockPagination.current_page,
					method: "GET",
				})
					.then((resp) => {
						this.stockLoader = false;

						this.stocks = resp.data;
						this.stockPagination.current_page =
							resp.current_page;
						this.stockPagination.total = resp.total;
						this.stockPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.stockLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		changeCategoryPage() {
			this.categoryLoader = true;
			if (this.filteredCategories == true) {
				this.categorySearchButtonDisabled = false;
				apiCall({
					url:
						"/api/category-part?part_id=" +
						this.assignedPart.id +
						"&page=" +
						this.categoryPagination.current_page +
						"&type=search&search=" +
						this.categorySearchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.categoryLoader = false;

						this.categories = resp.data;
						this.categoryPagination.current_page =
							resp.current_page;
						this.categoryPagination.total = resp.total;
						this.categoryPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.categoryLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				apiCall({
					url:
						"/api/category-part?part_id=" +
						this.assignedPart.id +
						"&page=" +
						this.categoryPagination.current_page,
					method: "GET",
				})
					.then((resp) => {
						this.categoryLoader = false;

						this.categories = resp.data;
						this.categoryPagination.current_page =
							resp.current_page;
						this.categoryPagination.total = resp.total;
						this.categoryPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.categoryLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		changeLevelPage() {
			this.levelLoader = true;
			if (this.filteredLevels == true) {
				this.levelSearchButtonDisabled = false;
				apiCall({
					url:
						"/api/level-part?part_id=" +
						this.assignedPart.id +
						"&page=" +
						this.levelPagination.current_page +
						"&type=search&search=" +
						this.levelSearchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.levelLoader = false;

						this.levels = resp.data;
						this.levelPagination.current_page =
							resp.current_page;
						this.levelPagination.total = resp.total;
						this.levelPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.levelLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				apiCall({
					url:
						"/api/level-part?part_id=" +
						this.assignedPart.id +
						"&page=" +
						this.levelPagination.current_page,
					method: "GET",
				})
					.then((resp) => {
						this.levelLoader = false;

						this.levels = resp.data;
						this.levelPagination.current_page =
							resp.current_page;
						this.levelPagination.total = resp.total;
						this.levelPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.levelLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},

		changeLearningAreaPage() {
			this.learningAreaLoader = true;
			if (this.filteredLearningAreas == true) {
				this.learningAreaSearchButtonDisabled = false;
				apiCall({
					url:
						"/api/learning-area-part?part_id=" +
						this.assignedPart.id +
						"&page=" +
						this.learningAreaPagination.current_page +
						"&type=search&search=" +
						this.learningAreaSearchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.learningAreaLoader = false;

						this.learningAreas = resp.data;
						this.learningAreaPagination.current_page =
							resp.current_page;
						this.learningAreaPagination.total = resp.total;
						this.learningAreaPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.learningAreaLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				apiCall({
					url:
						"/api/learning-area-part?part_id=" +
						this.assignedPart.id +
						"&page=" +
						this.learningAreaPagination.current_page,
					method: "GET",
				})
					.then((resp) => {
						this.learningAreaLoader = false;

						this.learningAreas = resp.data;
						this.learningAreaPagination.current_page =
							resp.current_page;
						this.learningAreaPagination.total = resp.total;
						this.learningAreaPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.learningAreaLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},

		getText(item) {
			return (
				item.first_name + " " + item.middle_name + " " + item.last_name + " #" + item.code
			);
		},
		showPart(part) {
			this.assignedPart = part;

			this.changeStockPage();
			this.changeCategoryPage();
			this.changeLevelPage();
			this.changeLearningAreaPage();
			this.changeView("show");
		},
		editPart(part) {
			this.newPart.id = part.id;
			this.newPart.name = part.name;
			this.newPart.brief = part.brief;
			this.newPart.serial_no = part.serial_no;
			this.newPart.description = part.description;
			this.newPart.max_purchase = part.max_purchase;

			if (this.newPart.max_purchase != null) {
				this.purchaseRestrictions = true
			}

			this.changeView("create");
		},

		editStock(stock) {
			this.newStock.id = stock.id;
			this.newStock.supplier_id = stock.supplier_id;
			this.newStock.invoice_no = stock.invoice_no;
			this.newStock.initial_quantity = stock.initial_quantity;
			this.newStock.buying_price = stock.buying_price;
			this.newStock.selling_price = stock.selling_price;
			this.newStock.expiry_date = stock.expiry_date;

			this.changeStockView("create");
		},

		editCategory(category) {
			for (var x = 0; x < this.formData.categories.length; x++) {
				if (this.formData.categories[x].id == category.category_id) {
					this.subCategories = this.formData.categories[x].sub_categories
					console.log("this.subCategories", this.subCategories)
				}
			}

			this.newCategory.id = category.id;
			this.newCategory.part_id = category.part_id;
			this.newCategory.category_id = category.category_id;
			this.newCategory.sub_category_id = category.subcategory_id;

			console.log("this.newCategory", this.newCategory)

			this.changeCategoryView("create");
		},

		editLevel(level) {
			for (var x = 0; x < this.formData.levels.length; x++) {
				if (this.formData.levels[x].id == level.level_id) {
					this.subLevels = this.formData.levels[x].sub_levels
					console.log("this.subLevels", this.subLevels)
				}
			}

			this.newLevel.id = level.id;
			this.newLevel.part_id = level.part_id;
			this.newLevel.level_id = level.level_id;
			this.newLevel.sub_level_id = level.sublevel_id;

			console.log("this.newLevel", this.newLevel)

			this.changeLevelView("create");
		},

		editLearningArea(learningArea) {
			this.newLearningArea.id = learningArea.id;
			this.newLearningArea.part_id = learningArea.part_id;
			this.newLearningArea.learning_area_id = learningArea.learning_area_id;

			console.log("this.newLearningArea", this.newLearningArea)

			this.changeLearningAreaView("create");
		},

		save() {
			if (this.$refs.form.validate()) {
				if (this.purchaseRestrictions == false) {
					this.newPart.max_purchase = null
				}
				if (this.newPart.id != null) {
					confirm("Are you sure you want to update part?") &&
					(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/part/" + this.newPart.id,
							data: this.newPart,
							method: "PUT",
						})
							.then((resp) => {
								this.message = "Part Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeView("default");
								this.fetchParts(1);
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				} else {
					confirm("Are you sure you want to create part?") &&
					(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/part",
							data: this.newPart,
							method: "POST",
						})
							.then((resp) => {
								this.message = "Part Added Successfully";
								this.color = "success";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeView("default");
								this.fetchParts(1);
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
								this.changeView("default");
								this.fetchParts(1);
							});
					}
				}
			}
		},

		saveStock() {
			if (this.$refs.stockForm.validate()) {
				if (this.newStock.id != null) {
					confirm("Are you sure you want to update stock?") &&
					(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/stock/" + this.newStock.id,
							data: this.newStock,
							method: "PUT",
						})
							.then((resp) => {
								this.message = "Stock Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeStockView("default");
								this.changeStockPage(1);
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				} else {


					confirm("Are you sure you want to add Stock?") &&
					(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						this.newStock.part_id = this.assignedPart.id;
						apiCall({
							url: "/api/stock",
							data: this.newStock,
							method: "POST",
						})
							.then((resp) => {
								this.message = "Stock Added Successfully";
								this.color = "success";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.newStock.user_id = null;
								this.changeStockView("default");
								this.changeStockPage();
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				}
			}
		},
		saveCategory() {
			if (this.$refs.categoryForm.validate()) {
				if (this.newCategory.id != null) {
					confirm("Are you sure you want to update category?") &&
					(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/category-part/" + this.newCategory.id,
							data: this.newCategory,
							method: "PUT",
						})
							.then((resp) => {
								this.message = "Category Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeCategoryView("default");
								this.changeCategoryPage(1);
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				} else {
					confirm("Are you sure you want to add Category?") &&
					(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						this.newCategory.part_id = this.assignedPart.id;
						apiCall({
							url: "/api/category-part",
							data: this.newCategory,
							method: "POST",
						})
							.then((resp) => {
								this.message = "Category Added Successfully";
								this.color = "success";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.newCategory.user_id = null;
								this.changeCategoryView("default");
								this.changeCategoryPage();
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				}
			}
		},

		saveLevel() {
			if (this.$refs.levelForm.validate()) {
				if (this.newLevel.id != null) {
					confirm("Are you sure you want to update level?") &&
					(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/level-part/" + this.newLevel.id,
							data: this.newLevel,
							method: "PUT",
						})
							.then((resp) => {
								this.message = "Level Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeLevelView("default");
								this.changeLevelPage(1);
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				} else {
					confirm("Are you sure you want to add Level?") &&
					(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						this.newLevel.part_id = this.assignedPart.id;
						apiCall({
							url: "/api/level-part",
							data: this.newLevel,
							method: "POST",
						})
							.then((resp) => {
								this.message = "Level Added Successfully";
								this.color = "success";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.newLevel.user_id = null;
								this.changeLevelView("default");
								this.changeLevelPage();
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				}
			}
		},

		saveLearningArea() {
			if (this.$refs.learningAreaForm.validate()) {
				if (this.newLearningArea.id != null) {
					confirm("Are you sure you want to update Learning Area?") &&
					(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/learning-area-part/" + this.newLearningArea.id,
							data: this.newLearningArea,
							method: "PUT",
						})
							.then((resp) => {
								this.message = "Learning Area Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeLearningAreaView("default");
								this.changeLearningAreaPage(1);
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				} else {
					confirm("Are you sure you want to add Learning Area?") &&
					(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						this.newLearningArea.part_id = this.assignedPart.id;
						apiCall({
							url: "/api/learning-area-part",
							data: this.newLearningArea,
							method: "POST",
						})
							.then((resp) => {
								this.message = "Learning Area Added Successfully";
								this.color = "success";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.newLearningArea.user_id = null;
								this.changeLearningAreaView("default");
								this.changeLearningAreaPage();
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				}
			}
		},

		search() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startPartLoader();

				axios.get(`/api/part?type=search&search=${this.searchTerm}&search_by=name,serial_no`)
					.then(({ data: resp }) => {
						this.filterParts(resp);
						this.searchLoader = false;
						this.stopPartLoader();
						this.filteredParts = true;
					})
					.catch((error) => {
						console.log(error);
						this.searchLoader = false;
						this.stopPartLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},


		stockSearch() {
			if (this.stockSearchTerm == null) {
				this.stockSearchButtonDisabled = true;
			} else {
				this.stockSearchLoader = true;
				this.stockSearchButtonDisabled = false;
				this.startPartLoader();
				apiCall({
					url:
						"/api/stock?type=search&search=" +
						this.stockSearchTerm +
						"&part_id=" +
						this.assignedPart.id,
					method: "GET",
				})
					.then((resp) => {
						this.stocks = resp.data;
						this.stockPagination.current_page =
							resp.current_page;
						this.stockPagination.total = resp.total;
						this.stockPagination.per_page = resp.per_page;

						this.stockSearchLoader = false;
						this.filteredStocks = true;
					})
					.catch((error) => {
						this.stockSearchLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						console.log(error);
					});
			}
		},
		categorySearch() {
			if (this.categorySearchTerm == null) {
				this.categorySearchButtonDisabled = true;
			} else {
				this.categorySearchLoader = true;
				this.categorySearchButtonDisabled = false;
				this.startPartLoader();
				apiCall({
					url:
						"/api/category-part?type=search&search=" +
						this.categorySearchTerm +
						"&part_id=" +
						this.assignedPart.id,
					method: "GET",
				})
					.then((resp) => {
						this.categories = resp.data;
						this.categoryPagination.current_page =
							resp.current_page;
						this.categoryPagination.total = resp.total;
						this.categoryPagination.per_page = resp.per_page;

						this.categorySearchLoader = false;
						this.filteredCategories = true;
					})
					.catch((error) => {
						this.categorySearchLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						console.log(error);
					});
			}
		},

		levelSearch() {
			if (this.levelSearchTerm == null) {
				this.levelSearchButtonDisabled = true;
			} else {
				this.levelSearchLoader = true;
				this.levelSearchButtonDisabled = false;
				this.startPartLoader();
				apiCall({
					url:
						"/api/level-part?type=search&search=" +
						this.levelSearchTerm +
						"&part_id=" +
						this.assignedPart.id,
					method: "GET",
				})
					.then((resp) => {
						this.levels = resp.data;
						this.levelPagination.current_page =
							resp.current_page;
						this.levelPagination.total = resp.total;
						this.levelPagination.per_page = resp.per_page;

						this.levelSearchLoader = false;
						this.filteredLevels = true;
					})
					.catch((error) => {
						this.levelSearchLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						console.log(error);
					});
			}
		},

		learningAreaSearch() {
			if (this.learningAreaSearchTerm == null) {
				this.learningAreaSearchButtonDisabled = true;
			} else {
				this.learningAreaSearchLoader = true;
				this.learningAreaSearchButtonDisabled = false;
				this.startPartLoader();
				apiCall({
					url:
						"/api/learning-area-part?type=search&search=" +
						this.learningAreaSearchTerm +
						"&part_id=" +
						this.assignedPart.id,
					method: "GET",
				})
					.then((resp) => {
						this.learningAreas = resp.data;
						this.learningAreaPagination.current_page =
							resp.current_page;
						this.learningAreaPagination.total = resp.total;
						this.learningAreaPagination.per_page = resp.per_page;

						this.learningAreaSearchLoader = false;
						this.filteredLearningAreas = true;
					})
					.catch((error) => {
						this.learningAreaSearchLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						console.log(error);
					});
			}
		},

		activatePart(item) {
			confirm("Are You Sure You Want to Activate Part?") &&
			(this.activate = true);
			if (this.activate) {
				this.activateLoading = true;
				this.partIndex = item.id;
				this.newPart.id = item.id;
				apiCall({
					url: "/api/part?type=activate",
					data: this.newPart,
					method: "POST",
				})
					.then((resp) => {
						this.message = "Part Account Activated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.partIndex = null;
						this.newPart.id = null;
						this.fetchParts(this.partPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.partIndex = null;
						this.newPart.id = null;
						console.log(error);
					});
			}
		},
		deactivatePart(item) {
			confirm("Are You Sure You Want to Deactivate Part?") &&
			(this.activate = true);
			if (this.activate) {
				this.deactivateLoading = true;
				this.partIndex = item.id;
				this.newPart.id = item.id;
				apiCall({
					url: "/api/part?type=deactivate",
					data: this.newPart,
					method: "POST",
				})
					.then((resp) => {
						this.message = "Part Account Deactivated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.partIndex = null;
						this.newPart.id = null;
						this.fetchParts(this.partPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.partIndex = null;
						this.newPart.id = null;
						console.log(error);
					});
			}
		},

		activateStock(item) {
			confirm(
				"Are You Sure You Want to Activate Stock?"
			) && (this.activate = true);
			if (this.activate) {
				this.activateLoading = true;
				this.stockIndex = item.id;
				this.newStock.id = item.id;
				apiCall({
					url: "/api/stock?type=activate",
					data: this.newStock,
					method: "POST",
				})
					.then((resp) => {
						this.message = "Stock Activated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.stockIndex = null;
						this.newStock.id = null;
						this.changeStockPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.stockIndex = null;
						this.newStock.id = null;
						console.log(error);
					});
			}
		},

		deactivateStock(item) {
			confirm(
				"Are You Sure You Want to Deactivate Stock?"
			) && (this.activate = true);
			if (this.activate) {
				this.deactivateLoading = true;
				this.stockIndex = item.id;
				this.newStock.id = item.id;
				apiCall({
					url: "/api/stock?type=deactivate",
					data: this.newStock,
					method: "POST",
				})
					.then((resp) => {
						this.message = "Stock Deactivated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.stockIndex = null;
						this.newStock.id = null;
						this.changeStockPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.stockIndex = null;
						this.newStock.id = null;
						console.log(error);
					});
			}
		},

		activateCategory(item) {
			confirm(
				"Are You Sure You Want to Activate Category?"
			) && (this.activate = true);
			if (this.activate) {
				this.activateLoading = true;
				this.categoryIndex = item.id;
				this.newCategory.id = item.id;
				apiCall({
					url: "/api/category-part?type=activate",
					data: this.newCategory,
					method: "POST",
				})
					.then((resp) => {
						this.message = "Category Activated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.categoryIndex = null;
						this.newCategory.id = null;
						this.changeCategoryPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.categoryIndex = null;
						this.newCategory.id = null;
						console.log(error);
					});
			}
		},

		deactivateCategory(item) {
			confirm(
				"Are You Sure You Want to Deactivate Category?"
			) && (this.activate = true);
			if (this.activate) {
				this.deactivateLoading = true;
				this.categoryIndex = item.id;
				this.newCategory.id = item.id;
				apiCall({
					url: "/api/category-part?type=deactivate",
					data: this.newCategory,
					method: "POST",
				})
					.then((resp) => {
						this.message = "Category Deactivated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.categoryIndex = null;
						this.newCategory.id = null;
						this.changeCategoryPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.categoryIndex = null;
						this.newCategory.id = null;
						console.log(error);
					});
			}
		},

		activateLevel(item) {
			confirm(
				"Are You Sure You Want to Activate Level?"
			) && (this.activate = true);
			if (this.activate) {
				this.activateLoading = true;
				this.levelIndex = item.id;
				this.newLevel.id = item.id;
				apiCall({
					url: "/api/level-part?type=activate",
					data: this.newLevel,
					method: "POST",
				})
					.then((resp) => {
						this.message = "Level Activated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.levelIndex = null;
						this.newLevel.id = null
						this.changeLevelPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.levelIndex = null;
						this.newLevel.id = null
						console.log(error);
					});
			}
		},

		deactivateLevel(item) {
			confirm(
				"Are You Sure You Want to Deactivate Level?"
			) && (this.activate = true);
			if (this.activate) {
				this.deactivateLoading = true;
				this.levelIndex = item.id;
				this.newLevel.id = item.id;
				apiCall({
					url: "/api/level-part?type=deactivate",
					data: this.newLevel,
					method: "POST",
				})
					.then((resp) => {
						this.message = "Level Deactivated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.levelIndex = null;
						this.newLevel.id = null
						this.changeLevelPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.levelIndex = null;
						this.newLevel.id = null
						console.log(error);
					});
			}
		},


		activateLearningArea(item) {
			confirm(
				"Are You Sure You Want to Activate Learning Area?"
			) && (this.activate = true);
			if (this.activate) {
				this.activateLoading = true;
				this.learningAreaIndex = item.id;
				this.newLearningArea.id = item.id;
				apiCall({
					url: "/api/learning-area-part?type=activate",
					data: this.newLearningArea,
					method: "POST",
				})
					.then((resp) => {
						this.message = "Learning Area Activated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.learningAreaIndex = null;
						this.newLearningArea.id = null;
						this.changeLearningAreaPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.learningAreaIndex = null;
						this.newLearningArea.id = null;
						console.log(error);
					});
			}
		},

		deactivateLearningArea(item) {
			confirm(
				"Are You Sure You Want to Deactivate LearningArea?"
			) && (this.activate = true);
			if (this.activate) {
				this.deactivateLoading = true;
				this.learningAreaIndex = item.id;
				this.newLearningArea.id = item.id;
				apiCall({
					url: "/api/learning-area-part?type=deactivate",
					data: this.newLearningArea,
					method: "POST",
				})
					.then((resp) => {
						this.message = "LearningArea Deactivated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.learningAreaIndex = null;
						this.newLearningArea.id = null;
						this.changeLearningAreaPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.learningAreaIndex = null;
						this.newLearningArea.id = null;
						console.log(error);
					});
			}
		},


		changeView(view) {
			if (view == "default") {
				this.newPart.id = null;
				this.newPart.name = null;
				this.newPart.brief = null;
				this.newPart.serial_no = null;
				this.newPart.description = null;
				this.newPart.max_purchase = null;

				this.purchaseRestrictions = false
			} else if (view == "create") {
				this.getFormData();
			} else if (view == "show") {
			}
			this.view = view;
		},

		changeStockView(view) {
			if (view == "default") {
				this.newStock.id = null;
				this.newStock.invoice_no = null;
				this.newStock.initial_quantity = null;
				this.newStock.buying_price = null;
				this.newStock.selling_price = null;
				this.newStock.expiry_date = null;
			} else if (view == "create") {
			} else if (view == "show") {
			}
			this.stockView = view;
		},

		changeCategoryView(view) {
			if (view == "default") {
				this.newCategory.id = null;
				this.newCategory.part_id = null;
				this.newCategory.category_id = null;
				this.newCategory.sub_category_id = null;
			} else if (view == "create") {
			} else if (view == "show") {
			}
			this.categoryView = view;
		},

		changeLevelView(view) {
			if (view == "default") {
				this.newLevel.id = null;
				this.newLevel.part_id = null;
				this.newLevel.level_id = null;
				this.newLevel.sub_level_id = null;
			} else if (view == "create") {
			} else if (view == "show") {
			}
			this.levelView = view;
		},

		changeLearningAreaView(view) {
			if (view == "default") {
				this.newLearningArea.id = null;
				this.newLearningArea.part_id = null;
				this.newLearningArea.learning_area_id = null;
			} else if (view == "create") {
			} else if (view == "show") {
			}
			this.learningAreaView = view;
		},

		changePage() {
			this.startPartLoader();
			if (this.filteredParts == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/part?page=" +
						this.partPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterParts(resp);
						this.stopPartLoader();
					})
					.catch((error) => {
						console.log(error);
						this.stopPartLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchParts(this.partPagination.current_page);
			}
		},
		deletePart(item) {
			confirm("Are You Sure You Want to Delete Part") &&
			(this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.partIndex = item.id;
				apiCall({ url: "/api/part/" + item.id, method: "DELETE" })
					.then((resp) => {
						this.message = "Part Deleted Successfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.partIndex = null;
						this.fetchParts(this.partPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.partIndex = null;
						console.log(error);
					});
			}
		},

		showDeleteStock(item) {
			this.assignedStock = item;
			console.log({ assignedStock: this.assignedStock })
			this.stockIndex = item.id;
			this.stockDiscard.quantity = this.assignedStock.current_quantity;
			this.changeView("delete-stock");
		},

		initiateDiscardStock() {
			// confirm("Are You Sure You Want to Discard the Stock?") &&
			// (this.delete = true);
			if (/*this.delete &&*/ this.assignedStock) {
				this.loading = true;
				this.stockIndex = this.assignedStock.id;
				apiCall({
					url: "/api/discarded-stocks/" + this.stockIndex,
					method: "POST",
					data: this.stockDiscard
				})
					.then((resp) => {
						this.message = "Request Sent Successfully... Awaiting Approval from Admin";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.stockIndex = null;
						this.changeView('default')
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.stockIndex = null;
						console.log(error);
					});
			}
		},

		deleteStock(item) {
			confirm("Are You Sure You Want to delete Stock?") &&
			(this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.stockIndex = item.id;
				apiCall({
					url: "/api/stock/" + item.id,
					method: "DELETE",
				})
					.then((resp) => {
						this.message = "Stock Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.stockIndex = null;
						this.changeStockPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.stockIndex = null;
						console.log(error);
					});
			}
		},

		deleteCategory(item) {
			confirm("Are You Sure You Want to Remove Category?") &&
			(this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.categoryIndex = item.id;
				apiCall({
					url: "/api/category-part/" + item.id,
					method: "DELETE",
				})
					.then((resp) => {
						this.message = "Category Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.categoryIndex = null;
						this.changeCategoryPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.categoryIndex = null;
						console.log(error);
					});
			}
		},

		deleteLevel(item) {
			confirm("Are You Sure You Want to Remove Level?") &&
			(this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.levelIndex = item.id;
				apiCall({
					url: "/api/level-part/" + item.id,
					method: "DELETE",
				})
					.then((resp) => {
						this.message = "Level Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.levelIndex = null;
						this.changeLevelPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.levelIndex = null;
						console.log(error);
					});
			}
		},

		deleteLearningArea(item) {
			confirm("Are You Sure You Want to Remove Learning Area?") &&
			(this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.learningAreaIndex = item.id;
				apiCall({
					url: "/api/learning-area-part/" + item.id,
					method: "DELETE",
				})
					.then((resp) => {
						this.message = "Learning Area Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.learningAreaIndex = null;
						this.changeLearningAreaPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.learningAreaIndex = null;
						console.log(error);
					});
			}
		},

		getFormData() {
			apiCall({
				url: "/api/part?type=formData",
				method: "GET",
			})
				.then((resp) => {
					this.formData = resp;
				})
				.catch((error) => {
					this.message = "anErrorOccurredCouldntGetFormDataMsg";
					this.color = "error";
				});
		},

		uploadImage() {
			if (this.$refs.imageForm.validate()) {
				this.imageLoading = true;
				let formData = new FormData();

				// files

				formData.append("files", this.imageFile, this.imageFile.name);
				formData.append("id", this.assignedPart.id);

				apiCall({
					url: "/api/part?type=image",
					data: formData,
					method: "POST",
				})
					.then((resp) => {
						this.snackbar = true;
						this.message = "Image Uploaded Successfully";
						this.color = "success";
						this.fetchParts(this.partPagination.current_page);
						this.imageLoading = false;
						this.imageDialog = false;
						this.imageFile = null;
						this.assignedPart.image = resp.image;
						// this.changeView('default')
					})
					.catch((error) => {
						this.imageLoading = false;
					});
			}
		},
		viewLog(data) {
			this.logData.message = data.id;
			this.logData.model = "App\\Models\\Part";
			this.filterLogData(this.logData);
			this.openLogDataModel();
		},
	},
	computed: {
		...mapGetters([
			"parts",
			"partPagination",
			"partLoader",
			"logDataModel"
		]),
		length: function () {
			return Math.ceil(
				this.partPagination.total / this.partPagination.per_page
			);
		},
		stockLength: function () {
			return Math.ceil(
				this.stockPagination.total /
				this.stockPagination.per_page
			);
		},
		categoryLength: function () {
			return Math.ceil(
				this.categoryPagination.total /
				this.categoryPagination.per_page
			);
		},
		levelLength: function () {
			return Math.ceil(
				this.levelPagination.total /
				this.levelPagination.per_page
			);
		},
		learningAreaLength: function () {
			return Math.ceil(
				this.learningAreaPagination.total /
				this.learningAreaPagination.per_page
			);
		},
		formatExpiryDate() {
			if (this.newStock.expiry_date != null) {
				const d = new Date(this.newStock.expiry_date);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},

		dateToday() {
			var today = new Date();

			var timestamp =
				today.getFullYear() +
				"-" +
				this.months[today.getMonth()] +
				"-" +
				("0" + today.getDate()).slice(-2);
			return timestamp;
		},
	},
};
</script>
  