<template>
	<div class="wallets" v-if="$can('view-wallets')">
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<div class="pa-5">
			<v-layout column>
				<div v-if="view === 'default'">
					<v-card elevation="0" class="mt-5">
						<v-flex xs12 class="mt-10 mb-2">
							<div class="mx-5">
								<v-layout row wrap>
									<v-flex xs12 md6>
										<div v-if="length !== 0" align="left" class="mt-1">
											<b class="display-1 primary--text">Total: </b
											>{{ walletPagination.total | formatNumber }}
										</div>
									</v-flex>
									<v-flex xs12 md4>
										<div class="pa-1">
											<v-text-field
												class="text_field background"
												outlined
												dense
												rounded
												label="Search"
												append-icon="mdi-undo-variant"
												@click:append="resetSearch()"
												v-on:keyup.enter="search"
												v-model="searchTerm"
												@input="enableSearch()"
											></v-text-field>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1" align="right">
											<v-btn
												class="button mx-1"
												icon
												@click="search"
												:loading="searchLoader"
												:disabled="searchButtonDisabled"
											>
												<v-icon class="secondary--text">mdi-magnify</v-icon>
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</v-flex>
						<v-divider class="mt-9 mx-5"></v-divider>
						<v-progress-linear
							v-if="walletLoader"
							height="1"
							indeterminate
							color="primary"
						>
						</v-progress-linear>
						<v-flex xs12 class="mt-5">
							<div v-if="wallets.length === 0">
								<v-card elevation="0">
									<v-layout row wrap>
										<v-flex xs12 md1>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														<v-icon large class="primary--text">
															mdi-alert-circle-outline
														</v-icon>
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
										<v-flex xs12 md11>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														No Wallet Found
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
									</v-layout>
								</v-card>
							</div>
							<div v-else>
								<div class="hidden-sm-and-down">
									<v-card elevation="0" class="pa-2">
										<v-simple-table>
											<template v-slot:default>
												<thead class="header">
												<tr class="accent">
													<th class="text-left text--text">User</th>
													<th class="text-left text--text">Balance</th>
													<th class="text-right text--text">Actions</th>
												</tr>
												</thead>
												<tbody>
												<tr v-for="item in wallets" :key="item.id">
													<td>{{ item.user.full_name }}</td>
													<td>{{ item.amount }}</td>
													<td>
														<div align="right">
															<v-btn
																icon
																v-if="$can('view-wallets')"
																@click="showWallet(item)"
																class="button mr-1 universal--text"
															>
																<v-icon small> mdi-eye</v-icon>
															</v-btn>
															<v-btn
																v-if="$can('delete-wallet')"
																icon
																class="button mr-1 error--text"
																@click="deleteWallet(item)"
																:loading="loading && walletIndex === item.id"
															>
																<v-icon small> mdi-delete</v-icon>
															</v-btn>
														</div>
													</td>
												</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>
								</div>
							</div>
						</v-flex>
					</v-card>
					<v-layout column class="mt-5">
						<v-flex xs12>
							<div align="left">
								<v-pagination
									v-if="length !== 0"
									:length="length"
									total-visible="10"
									v-model="walletPagination.current_page"
									@input="changePage()"
									circle
								>
								</v-pagination>
							</div>
						</v-flex>
					</v-layout>
				</div>
				<div v-if="view === 'show'">
					<v-card outlined class="mt-5">
						<v-card-title class="accent text--text">
							<v-spacer></v-spacer>
							<div>View Wallet</div>
							<v-spacer></v-spacer>
							<v-btn icon @click="changeView('default')">
								<v-icon> mdi-close</v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text>
							<div class="pa-1">
								<v-tabs show-arrows>
									<v-tabs-slider color="primary"></v-tabs-slider>

									<v-tab @click="activeTab = 'details'">
										<div class="text-none primary--text">Details</div>
									</v-tab>
									<v-tab v-if="$can('view-wallets')" @click="activeTab = 'wallets'">
										<div class="text-none primary--text">
											Transactions ({{ assignedWallet.wallet_logs.length }})
										</div>
									</v-tab>
								</v-tabs>
								<div v-if="activeTab === 'details'">
									<v-card-text>
										<div class="pa-3">
											<v-layout row wrap>
												<v-flex xs12 md10>
													<div class="mx-8">
														<v-layout column>
															<v-flex xs12>
																<v-layout row wrap>
																	<v-flex xs12 md6>
																		<v-layout column>
																			<v-flex xs12 class="mt-6">
																				<v-layout row wrap>
																					<v-flex xs12 md4>
																						<b>User:</b>
																					</v-flex>
																					<v-flex xs12 md8>
																						<div>
																							{{
																								assignedWallet.user.full_name
																							}}
																						</div>
																					</v-flex>
																				</v-layout>
																			</v-flex>
																			<v-flex xs12 class="mt-6">
																				<v-layout row wrap>
																					<v-flex xs12 md4>
																						<b>Balance: </b>
																					</v-flex>
																					<v-flex xs12 md8>
																						{{ assignedWallet.amount }}
																					</v-flex>
																				</v-layout>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</v-layout>
															</v-flex>
														</v-layout>
													</div>
												</v-flex>
											</v-layout>
										</div>
									</v-card-text>
								</div>

								<div v-if="activeTab === 'transactions'">
									<v-card elevation="0" class="mt-5">
										<v-flex xs12 class="mt-10 mb-2">
											<div class="mx-5">
												<v-layout row wrap>
													<v-flex xs12 md6>
														<div
															v-if="walletsLength !== 0"
															align="left"
															class="mt-1"
														>
															<b class="display-1">Wallet Transaction</b>
														</div>
													</v-flex>
												</v-layout>
											</div>
										</v-flex>
										<v-divider class="mt-9 mx-5"></v-divider>
										<v-flex xs12 class="mt-5">
											<div v-if="assignedWallet.wallet_logs.length === 0">
												<v-card elevation="0">
													<v-layout row wrap>
														<v-flex xs12 md1>
															<v-container fill-height fluid>
																<v-row align="center" justify="center">
																	<v-col class="text-center">
																		<v-icon large class="text--text">
																			mdi-alert-circle-outline
																		</v-icon>
																	</v-col>
																</v-row>
															</v-container>
														</v-flex>
														<v-flex xs12 md11>
															<v-container fill-height fluid>
																<v-row align="center" justify="center">
																	<v-col class="text-center">
																		No Wallet Transactions Found
																	</v-col>
																</v-row>
															</v-container>
														</v-flex>
													</v-layout>
												</v-card>
											</div>
											<div v-else>
												<div class="hidden-sm-and-down">
													<v-card elevation="0" class="pa-2">
														<v-simple-table>
															<template v-slot:default>
																<thead class="header">
																<tr class="accent">
																	<th class="text-left text--text">Transaction No</th>
																	<th class="text-left text--text">Transaction Code
																	</th>
																	<th class="text-left text--text">Amount</th>
																	<th class="text-left text--text">Date</th>
																</tr>
																</thead>
																<tbody>
																<tr v-for="log in assignedWallet.wallet_logs"
																    :key="log.id">
																	<td>{{ log.transaction_number }}</td>
																	<td>{{ log.transaction_code }}</td>
																	<td>{{ log.amount }}</td>
																	<td>
																		<div>{{
																				log.created_at | moment("DD/MM/YYYY")
																			}}
																		</div>
																		<small>{{
																				log.created_at | moment("hh:mm A")
																			}}</small>
																	</td>
																</tr>
																</tbody>
															</template>
														</v-simple-table>
													</v-card>
												</div>
												<div class="hidden-md-and-up">
													<div>
														<v-layout column>
															<template v-for="(
                                    log, index
                                  ) in assignedWallet.wallet_logs">
																<div :key="index">
																	<v-flex xs12 class="mb-2">
																		<v-card elevation="0"
																		        style="border-radius: 25px;" outlined>
																			<div class="pa-7 mt-2">
																				<v-layout column>
																					<v-flex xs12 class="mt-1">
																						<v-layout column>
																							<v-flex xs12 class="mt-5">
																								<v-layout row wrap>
																									<v-flex xs4>
																										<b>Transaction
																											No</b>
																									</v-flex>
																									<v-flex xs8>
																										{{
																											log.transaction_number
																										}}
																									</v-flex>
																								</v-layout>
																							</v-flex>
																							<v-flex xs12 class="mt-5">
																								<v-layout row wrap>
																									<v-flex xs4>
																										<b>Transaction
																											Code</b>
																									</v-flex>
																									<v-flex xs8>
																										{{
																											log.transaction_code
																										}}
																									</v-flex>
																								</v-layout>
																							</v-flex>
																							<v-flex xs12 class="mt-5">
																								<v-layout row wrap>
																									<v-flex xs4>
																										<b>Amount)</b>
																									</v-flex>
																									<v-flex xs8>
																										{{
																											log.amount | formatNumber
																										}}
																									</v-flex>
																								</v-layout>
																							</v-flex>
																							<v-flex xs12 class="mt-5">
																								<v-layout row wrap>
																									<v-flex xs4>
																										<b>Date
																											Created</b>
																									</v-flex>
																									<v-flex xs8>
																										<div>{{
																												log.created_at | moment("DD/MM/YYYY")
																											}}
																										</div>
																										<small>{{
																												log.created_at | moment("hh:mm A")
																											}}</small>
																									</v-flex>
																								</v-layout>
																							</v-flex>
																						</v-layout>
																					</v-flex>
																				</v-layout>
																			</div>
																		</v-card>
																	</v-flex>
																</div>
															</template>
														</v-layout>
													</div>
												</div>
											</div>
										</v-flex>
									</v-card>
									<v-layout column class="mt-5">
										<v-flex xs12>
											<div align="left">
												<v-pagination
													v-if="length !== 0"
													:length="walletsLength"
													total-visible="10"
													v-model="
                              walletsPagination.current_page
                            "
													circle
												>
												</v-pagination>
											</div>

										</v-flex>

									</v-layout>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</div>
			</v-layout>
		</div>
	</div>
</template>
<style>
.ck-editor__editable {
	min-height: 200px;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapActions, mapGetters } from "vuex";
import logData from "@/views/accessControl/logData";

export default {
	components: {
		logData,
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,

			editorConfig: {},

			importDialog: false,
			activeTab: "details",

			view: "default",

			loading: false,

			message: "",
			color: "",
			delete: false,
			activate: false,
			confirm: false,
			tab: null,
			walletIndex: null,
			snackbar: false,
			walletSearchTerm: null,
			years: [],
			assignedWallet: null,
			formData: null,

			searchTerm: null,
			searchLoader: false,

			searchButtonDisabled: false,
			walletsPagination: {
				search: " ",
				current_page: 1,
				per_page: 0,
				total: 0,
				visible: 10,
			},

			logData: {
				message: null,
				model: null,
			},
		};
	},
	created() {
		if (this.$can("view-wallets")) {
			this.startWalletLoader();
			this.fetchWallets(this.walletPagination.current_page);
			// this.populateYears();
		}
	},
	methods: {
		...mapActions([
			"fetchWallets",
			"startWalletLoader",
			"stopWalletLoader",
			"filterWallets",

			"filterLogData",
			"openLogDataModel",
		]),

		enableSearch() {
			this.searchButtonDisabled = false;
		},

		resetSearch() {
			this.searchTerm = null;
			this.startWalletLoader();
			this.fetchWallets(1);
		},
		showWallet(wallet) {
			this.assignedWallet = wallet;
			this.changeView("show");
		},
		search() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startWalletLoader();
				apiCall({
					url: "/api/wallets?type=search&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterWallets(resp);
						this.searchLoader = false;
						this.stopWalletLoader();
					})
					.catch((error) => {
						console.log(error);
						this.searchLoader = false;
						this.stopWalletLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},

		changeView(view) {
			this.view = view;
		},

		changePage() {
			this.fetchWallets(this.walletPagination.current_page);
		},
		deleteWallet(item) {
			confirm("Are You Sure You Want to Delete Wallet?") &&
			(this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.walletIndex = item.id;
				apiCall({ url: "/api/wallets/" + item.id, method: "DELETE" })
					.then(() => {
						this.message = "Wallets Deleted Successfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.walletIndex = null;
						this.fetchWallets(this.walletPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.walletIndex = null;
						console.log(error);
					});
			}
		},
	},
	computed: {
		...mapGetters([
			"wallets",
			"walletPagination",
			"walletLoader"
		]),
		length: function () {
			return Math.ceil(
				this.walletPagination.total / this.walletPagination.per_page
			);
		},
		walletsLength: function () {
			return Math.ceil(
				this.walletsPagination.total /
				this.walletsPagination.per_page
			);
		},

		dateToday() {
			let today = new Date();

			return `${today.getFullYear()}-${this.months[today.getMonth()]}-${("0" + today.getDate()).slice(-2)}`;
		},
	},
};
</script>
  